import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ApiService } from "../../../_services/api.service";
import { environment } from '../../../../environments/environment';

import { Observable, Subscription } from "rxjs";
import { filter, distinctUntilChanged, switchMap, debounceTime } from "rxjs/operators";
import { catchError, map, startWith } from 'rxjs/operators';
import { of } from 'rxjs';

import * as moment from "moment";
import * as _ from 'underscore';

@Component({
  selector: 'app-view-workorder-dialog',
  templateUrl: './view-workorder-dialog.component.html',
  styleUrls: ['./view-workorder-dialog.component.scss']
})
export class ViewWorkorderDialogComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  // parts live search
  // debounce observable guide:
  // https://www.tektutorialshub.com/angular/debouncetime-debounce-in-angular/
  partsSearchObservable: Subscription;
  loadingPartsSearch: boolean = false;
  didFirstPartsSearch: boolean = false;
  parts: any = [];
  newPartForm: FormGroup;
  selectedPart: string = '';
  newPart: any = {};

  formLoaded: boolean = false;
  loading: boolean = true;
  options: any = {};
  techs: any = [];
  form: FormGroup;
  allowSchedule: boolean = false;
  invoiced: boolean = false;
  doShowConfirmDelete: boolean = false;

  newLaborTime: any = {};

  constructor(
    private _snackBar: MatSnackBar,
    private api: ApiService,
    public dialogRef: MatDialogRef<ViewWorkorderDialogComponent>,
    private fb: FormBuilder,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public workorder: any
  ) {
    this.initFormGroup();
    this.initPartsSearchObservable();

    this.allowSchedule = this.workorder.schedule;
    this.invoiced = this.workorder.invoiced;

    delete this.workorder.schedule;
    delete this.workorder.schedule;
  }

  ngOnInit(): void {
    this.init();
  }

  async init() {
    await this.getOptions();
    await this.getTechs();
    this.groupLaborTimes();

    // this.initFormGroup();
  }

  initPartsSearchObservable() {
    this.newPartForm = this.fb.group({
      parts_search: ['', []]
    });

    // set up parts search observable
    this.partsSearchObservable = this.newPartForm.controls['parts_search'].valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((query: string, index: number) => {
          // min 2 character search length
          return query && query.length >= 2;
        }),
        switchMap(term => {
          this.loadingPartsSearch = true;
          return this.getSearchPartsRequest(term);
        })
      )
      .subscribe((response: any) => {
        this.loadingPartsSearch = false;
        this.didFirstPartsSearch = true;
        this.parts = response.data;
      });
  }

  initFormGroup() {
    let defaultDate: any = Date();

    if (this.workorder.scheduled_date) {
      defaultDate = moment(this.workorder.scheduled_date).toDate();
    }

    let additionalTechIds = this.workorder.additional_techs.map((item) => {
      return item.id;
    });

    this.form = this.fb.group({
      scheduled_date: [defaultDate, [Validators.required]],
      tech_id: [this.workorder.tech_id, [Validators.required]],
      status: [this.workorder.status, [Validators.required]],
      additional_tech_id: [additionalTechIds, []],
      comments: [this.workorder.comments, []]
    });

    return;
  }

  submitSave() {
    const id = this.workorder.id;

    let data = this.form.getRawValue();

    data['scheduled_date'] = moment(data['scheduled_date']).format('YYYY-MM-DD');

    this.api.post(`workorder/${id}`, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        this.openSuccessSnackBar('Saved!');
        this.onClose();
      } else {
        let errors = 'An error occurred';

        if (data && data.hasOwnProperty('error')) {
          errors = '';

          for (const x in data.error.data) {
            errors += `${data.error.data[x]}`;
          }
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
      }
    });
  }

  submitSendToQuickbooks() {
    let data = {
      workorder_ids: [this.workorder.id]
    };

    this.api.post(`workorder/quickbooks`, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        this.openSuccessSnackBar('Sent to Quickbooks!');
        this.onClose();
      } else {
        let errors = 'An error occurred';

        if (data && data.hasOwnProperty('error')) {
          errors = '';

          for (const x in data.error.data) {
            errors += `${data.error.data[x]}`;
          }
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
      }
    });
  }

  submitUnschedule() {
    const id = this.workorder.id;

    let url = `workorder/${id}/unschedule`;

    this.api.post(url).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        // this.client = data.data;
        this.openSuccessSnackBar('Unscheduled!');
        this.onClose();
        this.loading = false;
      } else {
        let errors = '';
        if (data && data.hasOwnProperty('error')) {
          for (const x in data.error.data) {
            errors += `${data.error.data[x]}`;
          }
          this.loading = false;
        } else {
          errors = 'An error occurred';
          this.loading = false;
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
        this.loading = false;
      }
    });

  }

  showConfirmDelete() {
    this.doShowConfirmDelete = true;
  }

  onSubmitDelete() {
    const id = this.workorder.id;

    let data = {
      _method: "DELETE"
    };

    let url = `workorder/${id}`;

    this.api.post(url, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        // this.client = data.data;
        this.openSuccessSnackBar('Deleted!');
        this.onClose();
        this.loading = false;
      } else {
        let errors = '';
        if (data && data.hasOwnProperty('error')) {
          for (const x in data.error.data) {
            errors += `${data.error.data[x]}`;
          }
          this.loading = false;
        } else {
          errors = 'An error occurred';
          this.loading = false;
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
        this.loading = false;
      }
    });
  }

  /** labor time */

  hasValidNewLaborTime() {
    return this.newLaborTime.start_time && this.newLaborTime.end_time;
  }

  getNewLaborTimeDuration() {
    if (!this.hasValidNewLaborTime()) {
      return '00:00:00';
    }

    // calculate time duration
    let startTime = this.newLaborTime.start_time;
    let endTime = this.newLaborTime.end_time;

    if (endTime < startTime) {
      startTime = moment().format('YYYY-MM-DD') + ' ' + startTime;
      endTime = moment().add(1, 'day').format('YYYY-MM-DD') + ' ' + endTime;
    } else {
      startTime = moment().format('YYYY-MM-DD') + ' ' + startTime;
      endTime = moment().format('YYYY-MM-DD') + ' ' + endTime;
    }

    const duration = moment.duration(moment(endTime).diff(startTime));

    const hours = `${parseInt(duration.asHours() as any) < 10 ? '0' + parseInt(duration.asHours() as any) : parseInt(duration.asHours() as any)}`;
    const minutes = `${(parseInt(duration.asMinutes() as any) % 60) < 10 ? '0' + (parseInt(duration.asMinutes() as any) % 60) : (parseInt(duration.asMinutes() as any) % 60)}`
    const timeString = `${hours}:${minutes}:00`;

    return timeString;
  }

  submitLaborTime(): void {
    const laborCodeKey = 'LE1001';

    const url = `workorder/${this.workorder.id}/labor-time`;

    const timeString = this.getNewLaborTimeDuration();

    const [startHour, startMins] = this.newLaborTime.start_time.split(':');
    const startTimeUTCHour = new Date(new Date().setHours(startHour)).getUTCHours();
    const startTime = String(startTimeUTCHour) + ':' + startMins;

    const [endHour, endMins] = this.newLaborTime.end_time.split(':');
    const endTimeUTCHour = new Date(new Date().setHours(endHour)).getUTCHours();
    const endTime = String(endTimeUTCHour) + ':' + endMins;

    const data = {
      labor_code_key: laborCodeKey,
      start_time: startTime,
      end_time: endTime,
      labor_time: timeString
    };

    this.api.post(url, data).subscribe(async (r: any) => {
      if (r.error) {
        try {
          let message = '';
          for (const key of Object.keys(r.data)) {
            message += r.data[key];
          }

          this.openErrorSnackBar(message);
        } catch (e) {
          const errorMsg = 'Error: Start and End times selected are invalid.';
          this.openErrorSnackBar(errorMsg);
          this.loading = false;
        }
      } else {
        const message = 'Time has been updated';
        this.openSuccessSnackBar(message);

        this.newLaborTime = {};

        this.workorder.labor_times.push(r.data);
        this.groupLaborTimes();
      }
    });
  }

  deleteLaborTime(laborTime){
    let url = `workorder/labor-time/${laborTime.id}`;
    let data = {
      _method: 'DELETE'
    };

    this.api.post(url, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      this.loading = false;

      if (data.status === 'success') {
        this.removeLaborTimeFromWorkOrder(laborTime);
        this.openSuccessSnackBar('Time removed');
      } else {
        let errors = 'An error occurred';

        if (data && data.hasOwnProperty('error')) {
          errors = '';

          for (const x in data.error.data) {
            errors += `${data.error.data[x]}`;
          }
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
      }
    });
  }

  removeLaborTimeFromWorkOrder(laborTime){
    let index = this.workorder.labor_times.findIndex((element) => {
      return element.id == laborTime.id;
    });

    this.workorder.labor_times.splice(index, 1);
    this.groupLaborTimes();
  }

  groupLaborTimes() {
    this.workorder.labor_times.forEach((e) => {
      e.created_at_grouped = moment(e.created_at).format('YYYY-MM-DD').toString();
      e.labor_time_grouped = e.labor_time.substr(0, 5)
    });

    this.workorder.labor_times_grouped = _.groupBy(this.workorder.labor_times, 'created_at_grouped');
  }

  /** used parts */

  getSearchPartsRequest(searchStr: string): any {
    this.parts = [];

    let url = 'workorder/parts/search';
    let data = {
      filter: searchStr,
      page_size: 50
    };

    return this.api.post(url, data);
  }

  newPartClick(event: any) {
    let partName = event.option.value;

    this.selectedPart = partName;
    this.newPart = this.getSelectedPart();
    this.newPart.count = null;
  }

  getSelectedPart() {
    let part = null;

    this.parts.forEach(element => {
      if (element.description == this.selectedPart) {
        part = element;
      }
    });

    return part;
  }

  submitUsedPart() {
    let url = `workorder/${this.workorder.id}/used-part`;
    let data = {
      part_id: this.newPart.id,
      used_count: this.newPart.count
    };

    this.api.post(url, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      this.loading = false;

      if (data.status === 'success') {
        this.workorder.used_parts.push(data.data);
        this.newPartForm.reset();
        this.newPart = {};
        this.openSuccessSnackBar('Part has added');
      } else {
        let errors = 'An error occurred';

        if (data && data.hasOwnProperty('error')) {
          errors = '';

          for (const x in data.error.data) {
            errors += `${data.error.data[x]}`;
          }
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
      }
    });
  }

  showConfirmRemoveItem(item){
    item.show_confirm_remove = true;
  }

  deleteUsedPart(part){
    let url = `workorder/used-part/${part.id}`;
    let data = {
      _method: 'DELETE'
    };

    this.api.post(url, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      this.loading = false;

      if (data.status === 'success') {
        this.removePartFromWorkOrder(part);
        this.openSuccessSnackBar('Part removed');
      } else {
        let errors = 'An error occurred';

        if (data && data.hasOwnProperty('error')) {
          errors = '';

          for (const x in data.error.data) {
            errors += `${data.error.data[x]}`;
          }
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
      }
    });
  }

  removePartFromWorkOrder(part){
    let index = this.workorder.used_parts.findIndex((element) => {
      return element.id == part.id;
    });

    this.workorder.used_parts.splice(index, 1);
  }

  /** util */

  async getOptions() {
    const results: any = await this.api.get('workorder/field-options').toPromise();
    return this.options = results.data;
  }

  async getTechs() {
    const results: any = await this.api.post('tech/search').toPromise();
    return this.techs = results.data
  }

  formatDateString(string) {
    return moment(string).format('MM/DD/YYYY');
  }

  formatLaborTime(string) {
    let hour = String(string.split(':')[0]).replace(/^(0\.)/, "");
    let minutes = String(string.split(':')[1]).replace(/^(0\.)/, "");

    if (hour.indexOf('0') === 0) hour = hour.slice(1);
    // if (minutes.indexOf('0') === 0) minutes = minutes.slice(1);

    return `${hour}h ${minutes}m`;
  }

  formatTime(timeStr) {
    let time = moment('2000-01-01 ' + timeStr);

    return time.format('hh:mma')
  }

  goToEditWorkOrder(id): void {
    // https://localhost:4200/staff/workorder/edit/36?returnRoute=staff%2Fschedule%2Findex
    // Get the route to pass
    const index: number = this.router.url.indexOf('admin');
    const url = `/${index !== -1 ? 'admin' : 'staff'}/workorder/edit/${id}?returnRoute=${this.router.url}`
    this.router.navigateByUrl(url);
    this.onClose();
  }

  goToEditWorkOrderPdf(id): void {
    const pdfUrl = `${environment.apiProtocol}://${environment.apiBaseUrl}/workorder/${id}/pdf`;
    window.open(pdfUrl, '_blank').focus();
  }

  cancel() {
    this.dialogRef.close({ success: false });
  }

  onClose() {
    this.dialogRef.close({ success: true });
  }

  openSuccessSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openErrorSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      panelClass: "error-snack-bar",
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
