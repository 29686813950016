import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from "../../../_services/api.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {of} from "rxjs";
import {catchError, debounceTime} from "rxjs/operators";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {CreateWorkorderDialogComponent} from "../create-workorder-dialog/create-workorder-dialog.component";
import {NotifierService} from "../../../_services/notifier.service";

@Component({
  selector: 'app-add-work-order-dialog',
  templateUrl: './add-work-order-dialog.component.html',
  styleUrls: ['./add-work-order-dialog.component.scss']
})
export class AddWorkOrderDialogComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  workorder_id: any = '';;
  unscheduled: any = [];
  form: FormGroup;
  loading: boolean = true;

  constructor(
    private _snackBar: MatSnackBar,
    private api: ApiService,
    public dialogRef: MatDialogRef<AddWorkOrderDialogComponent>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifier: NotifierService
  ) {
    this.notifier.createWorkorderDialog$.subscribe((data:any)=> {
      if (data.success) {
        this.dialogRef.close();
      }
    })

    this.form = this.fb.group({
      search: [{value: '', disabled: false}, []],
    });

    this.form.get('search').valueChanges.pipe(debounceTime(250)).subscribe((data: any) => {
      this.getUnscheduled(data);
    })
  }

  ngOnInit(): void {
    this.initData();
  }

  async initData(){
    this.getUnscheduled();
  }

  getUnscheduled(data = null) {
    this.loading = true;
    let url = 'workorder/unscheduled';
    if (data) url = `${url}?customer_name=${data}`;
    this.api.get(url).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'fail') {
        this.loading = false;
      } else {
        this.unscheduled = data.data;
        this.loading = false;
      }
    })
  }

  getWoPriorityColor(wo){
    if(wo.priority_info){
      return wo.priority_info.color;
    }else{
      return '#ffffff';
    }
  }

  createWorkOrder() {
    const dialogRef = this.dialog.open(CreateWorkorderDialogComponent, {
      width: '800px',
      data: {tech_id: this.data.tech_id, scheduled_date: this.data.scheduled_date, status: 'scheduled'}
    });
    this.onClose();
    //this.dialogRef.close({success: false});
  }

  assignToTech() {
    this.loading = true;
    this.api.post(`workorder/${this.workorder_id}`, {tech_id: this.data.tech_id, scheduled_date: this.data.scheduled_date, status: 'scheduled'}).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        // this.client = data.data;
        this.openSuccessSnackBar('Saved!');
        this.onClose();
        this.loading = false;
      } else {
        let errors = '';
        if (data && data.hasOwnProperty('error')) {
          for (const x in data.error.data) {
            errors += `${data.error.data[x]}`;
          }
          this.loading = false;
        } else {
          errors = 'An error occurred';
          this.loading = false;
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
        this.loading = false;
      }
    });
  }

  onClose() {
    this.dialogRef.close({success: true});
  }

  openSuccessSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openErrorSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      panelClass: "error-snack-bar",
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
