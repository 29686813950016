<div fxLayout="row" fxLayoutAlign="start center">
  <h1 class="ml-4">Schedule</h1>
</div>

<!-- <div fxLayout="row" fxLayoutAlign="start center">
  <form [formGroup]="form" style="margin: 0 auto">
    <button mat-button (click)="incrementDate('backward')">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <mat-form-field class="m-auto" appearance="fill">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="selectedDate" (click)="picker.open()">
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <button mat-button (click)="incrementDate('forward')">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </form>
</div> -->

<div>
  <mat-spinner [diameter]="40" *ngIf="loading"></mat-spinner>
</div>

<div cdkDropListGroup *ngIf="!loading">
  <!-- no techs message -->
  <h3 *ngIf="schedule.length < 1" class="text-center">
    No techs scheduled today
  </h3>

  <div class="row text-center">
    <div class="col-md-12" *ngIf="searchResults.filteredData.length === 0">
      <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </div>
    <!--
    <div class="col-md-4">
      <div class="btn-group">
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month"
        >
          Month
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week"
        >
          Week
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day"
        >
          Day
        </div>
      </div>
    </div>
    -->
  </div>
  <div class="row text-center">
    <div class="col-md-4">
      <form>
        <mat-form-field appearance="fill">
          <mat-label>Technician</mat-label>
          <mat-select
            [formControl]="technicianFormControl"
            multiple
            (blur)="handleTechnicianSearch()"
          >
            <mat-option *ngFor="let tech of techniciansList" value="{{ tech.tech.id }}">{{ getTechNameDisplay(tech) }}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="col-md-4">
      <button
        *ngIf="searchResults.filteredData.length > 0; else calendarcontrol"
        mat-raised-button
        (click)="clearSearchResults()"
      >
        <mat-icon>calendar_today</mat-icon>
      </button>
      <ng-template #calendarcontrol>
        <div class="btn-group">
          <div
            class="btn btn-primary"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
          >
            Previous
          </div>
          <div
            class="btn btn-outline-secondary"
            mwlCalendarToday
            [(viewDate)]="viewDate"
          >
            Today
          </div>
          <div
            class="btn btn-primary"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
          >
            Next
          </div>
        </div>
      </ng-template>
    </div>
    <div class="col-md-4">
      <form>
        <mat-form-field appearance="fill">
          <mat-label>Search</mat-label>
          <input
            type="text"
            matInput
            [formControl]="searchFormControl"
            (keyup)="flexSearch()"
          />
          <mat-error *ngIf="searchFormControl.hasError('internal')">
            Whoops, we're having an internal error!
          </mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>

  <br />

  <div *ngIf="searchResults.filteredData.length == 0;else tableResults;" [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-month-view>
    <!-- <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [dayStartHour]="4"
      [dayEndHour]="19"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [dayStartHour]="4"
      [dayEndHour]="19"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-day-view> -->
  </div>
  <ng-template #tableResults>
    <table mat-table [dataSource]="searchResults" class="mat-elevation-z8">
      <!-- Customer -->
      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef>Customer</th>
        <td mat-cell *matCellDef="let workorder">{{ workorder.customer.customer_name }}</td>
      </ng-container>

      <!-- Scheduled Date -->
      <ng-container matColumnDef="scheduledDate">
        <th mat-header-cell *matHeaderCellDef>Scheduled Date</th>
        <td mat-cell *matCellDef="let workorder">{{ workorder.scheduled_date }}</td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let workorder">{{ workorder.status.toUpperCase() }}</td>
      </ng-container>

      <!-- Tech -->
      <ng-container matColumnDef="tech">
        <th mat-header-cell *matHeaderCellDef>Tech</th>
        <td mat-cell *matCellDef="let workorder">
          <span *ngIf="workorder.tech; else blankcell;">
            {{ getTechNameDisplay(workorder.tech.user) }}
          </span>
          <ng-template #blankcell></ng-template>
        </td>
      </ng-container>

      <!-- Action -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let workorder"><a (click)="openViewWorkorder(workorder)" [routerLink]="">Edit</a></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </ng-template>

  <!-- Older WO Layout -->
  <!-- tech work orders -->
  <!--
  <div fxLayout="row" fxLayoutAlign=" none">
    <div *ngFor="let tech of schedule" cdkDropList [cdkDropListData]="tech.work_orders"
      (cdkDropListDropped)="drop($event, tech.id)">
      <div class="workorder-container w-100">
        <h2 class="text-center">{{ getTechNameDisplay(tech) }}</h2>

        <div class="workorder-list">
          <div class="workorder-box w-100" fxLayout="column">
            <ng-container *ngFor="let item of tech.work_orders">
              <mat-card class="tech-work-order mt-10 mb-10" [ngStyle]="{'border-right-color': getWoPriorityColor(item) }" cdkDrag>
                <mat-icon *ngIf="item.tech_completed_at" class="text-success wo-complete-icon" aria-hidden="true">done</mat-icon>

                <h1>
                  {{item.customer.customer_name}}
                </h1>
                <mat-card-subtitle>Created: {{item.created_at | date: 'shortDate'}}</mat-card-subtitle>
                <mat-card-content *ngIf="item.pivot">
                  <p>
                    {{ item.pivot.brand }} | {{ item.pivot.field_name }}
                  </p>
                  <p>
                    Problem Type: {{ item.problem_type_label }}
                  </p>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-flat-button (click)="openViewWorkorder(item)" color="primary">Details</button>
                </mat-card-actions>
              </mat-card>
            </ng-container>
            <button mat-raised-button (click)="openAssignmentModal(tech.id)">
              <mat-icon>add</mat-icon> Work Order
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
  -->
</div>