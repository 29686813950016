import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiService } from '../../../_services/api.service';

@Component({
  selector: 'app-create-pivot-dialog',
  templateUrl: './create-pivot-dialog.component.html',
  styleUrls: ['./create-pivot-dialog.component.scss']
})
export class CreatePivotDialogComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  customerId: any;
  pivot: any = {};

  form: FormGroup;
  submitted: Boolean = false;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CreatePivotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.customerId = data.customer_id;

    this.form = this.fb.group({
      legal: ['', [Validators.required]],
      brand: ['', [Validators.required]],
      mileage: ['', [Validators.required]],
      field_name: ['', [Validators.required]],
      longitude: [''],
      latitude: ['']
   });
  }

  ngOnInit(): void {
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let url = 'pivot';
    let data = this.form.getRawValue();
    data['customer_id'] = this.customerId;

    this.api.post(url, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        this.openSuccessSnackBar('Saved!');
        this.onClose(data.data);
      }else{
        let errors = '';
        if(data && data.hasOwnProperty('error')){
          for (const x in data.error.data) {
              errors += `${data.error.data[x]}`;
          }
        }else{
          errors = 'An error occurred';
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
      }
    });
  }

  openErrorSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      panelClass: "error-snack-bar",
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openSuccessSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  onClose(_pivotData=null){
    let data = {
      pivotData: _pivotData
    };
    this.dialogRef.close(data);
  }
}
