<mat-toolbar color="primary" class="main-nav mat-elevation-z4">

    <button mat-icon-button (click)="start.toggle()" class="icon-padding">
        <mat-icon aria-hidden="true">menu</mat-icon>
    </button>
    <!-- <button mat-button routerLink="/admin/dashboard">{{ title }}</button> -->
    <img class="logo-image" src="./assets/images/logo_circle.png"/>

    <!-- This fills the remaining space of the current row -->
    <span class="fill-remaining-space"></span>
  <button
    mat-raised-button
    style="background-color: white; color: #E71D26"
    class="icon-padding"
    [routerLink]="'workorder/create'">Create Work Order</button>
    <button
        mat-button
        class="icon-padding"
        (click)="logout()">
        <mat-icon aria-hidden="true">logout</mat-icon>
      Exit
    </button>

</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
    <mat-sidenav
        #start
        fixedInViewport="true"
        fixedTopGap="56"
        [opened]="open"
        [mode]="mode"
        class="mat-elevation-z10 sidenav-list-container"
        style="width:240px;">
        <mat-list role="list" style="margin-top: 20px;">
            <mat-list-item
                *ngFor="let item of navItems"
                (click)="mode === 'over'? start.close() : null"
                [routerLink]="item.link"
                [routerLinkActive]="['is-active']"
                class="side-nav-item"
                role="listitem">
                <mat-icon mat-list-icon>{{ item.icon }}</mat-icon> {{ item.label }}
            </mat-list-item>
          </mat-list>
    </mat-sidenav>

    <mat-sidenav-content style="margin-top: 64px;">
        <div style="margin: 20px 20px 80px 20px;">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>

<!-- <mat-menu x-position="before" #menu="matMenu">
    <button mat-menu-item routerLink="/profile"><mat-icon aria-hidden="true">settings</mat-icon>Settings</button>
    <button mat-menu-item routerLink="/profile"><mat-icon aria-hidden="true">account_box</mat-icon>Profile</button>
    <button mat-menu-item routerLink="/profile"><mat-icon aria-hidden="true">exit_to_app</mat-icon>Sign out</button>
</mat-menu> -->
