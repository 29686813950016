import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';

import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiService } from '../../../../_services/api.service';
import { ConfirmDialogComponent } from '../../../../_dialogs/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-pivot-show',
  templateUrl: './pivot-show.component.html',
  styleUrls: ['./pivot-show.component.scss']
})
export class PivotShowComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  pivotId: any;
  pivot: any = {};

  form: FormGroup;
  submitted: Boolean = false;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private _location: Location,
    public dialog: MatDialog
  ) {
    this.pivotId = this.route.snapshot.params['id'];

    this.form = this.fb.group({
      legal: [{value: '', disabled: false}, []],
      brand: [{value: '', disabled: false}, []],
      mileage: [{value: '', disabled: false}, []],
      field_name: [{value: '', disabled: false}, []],
      longitude: [{value: '', disabled: false}],
      latitude: [{value: '', disabled: false}],
    });
  }

  ngOnInit(): void {
    this.getPivot();
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  getPivot(){
    let url = `pivot/${this.pivotId}`;

    this.api.get(url).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'fail'){
        console.log('Get pivot failed');
      }else{
        this.pivot = data.data;
      }
    });
  }

  onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let url = 'pivot/' + this.pivotId;
    let data = this.form.getRawValue();

    this.api.post(url, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        // this.client = data.data;
        this.openSuccessSnackBar('Saved!');
      }else{
        let errors = '';
        if(data && data.hasOwnProperty('error')){
          for (const x in data.error.data) {
              errors += `${data.error.data[x]}`;
          }
        }else{
          errors = 'An error occurred';
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
      }
    });
  }

  openErrorSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      panelClass: "error-snack-bar",
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openSuccessSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openConfirmDelete(): void {
    const _message = 'Are you sure you want to delete this pivot?';

    const dialogData = {
      title: "Delete Pivot",
      message: _message
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.submitDelete();
      }
    });
  }

  submitDelete(){
    let url = 'pivot/' + this.pivotId;
    let data = {
      _method: 'DELETE'
    };

    this.api.post(url, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        this.openSuccessSnackBar('Pivot deleted!');
        this.goBack();
      }else{
        let errors = '';
        if(data && data.hasOwnProperty('error')){
          for (const x in data.error.data) {
              errors += `${data.error.data[x]}`;
          }
        }else{
          errors = 'An error occurred';
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
      }
    });
  }

  goBack(){
    this._location.back();
  }
}
