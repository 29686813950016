<div *ngIf="loading; else page" class="ion-padding-top ion-text-center">
  <mat-spinner [diameter]="40"></mat-spinner>
</div>
<ng-template #page>
  <!-- no work orders message-->
  <div *ngIf="invoiced.length < 1" class="mb-20">
      No invoiced work orders
  </div>

  <div class="mat-elevation-z8">
    <table class="w-100" mat-table [dataSource]="dataSource">

      <!-- Customer Column -->
      <ng-container matColumnDef="customer_name">
        <th mat-header-cell *matHeaderCellDef> Customer </th>
        <td mat-cell *matCellDef="let element"> {{ element.customer.customer_name }} </td>
      </ng-container>

      <!-- Scheduled Column -->
      <ng-container matColumnDef="schedule_date">
        <th mat-header-cell *matHeaderCellDef> Scheduled Date </th>
        <td mat-cell *matCellDef="let element"> {{ formatDateString(element.scheduled_date) }} </td>
      </ng-container>

      <!-- Pivot Column -->
      <ng-container matColumnDef="pivot">
        <th mat-header-cell *matHeaderCellDef> Pivot </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.pivot">
            {{ element.pivot.field_name ? element.pivot.field_name + ': ' : '' }} {{ element.pivot.legal }} {{ element.pivot.brand ? '- ' + element.pivot.brand : '' }}
          </span>
        </td>
      </ng-container>

      <!-- Problem Column -->
      <ng-container matColumnDef="problem_type">
        <th mat-header-cell *matHeaderCellDef> Problem Type </th>
        <td mat-cell *matCellDef="let element"> {{ element.problem_type_label }} </td>
      </ng-container>

      <!-- Quickbooks Status Column -->
      <ng-container matColumnDef="quickbook_status">
        <th mat-header-cell *matHeaderCellDef> Quickbooks Status </th>
        <td mat-cell *matCellDef="let element">{{ element.quickbook_status.qb_status }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <tr
        class="hover-pointer hover-table-row"
        mat-row
        *matRowDef="let row; columns: displayedColumns;"
        (click)="openViewWorkorderDetails(row)">
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
</ng-template>
