import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-staff-layout',
  templateUrl: './staff-layout.component.html',
  styleUrls: ['./staff-layout.component.scss']
})
export class StaffLayoutComponent implements OnInit {

  //Sidenav responsive
  width;
  height;
  mode:string = 'side';
  open = 'true';
  title = 'Pivot Electric';
  navItems: any = [];

  constructor(
    public ngZone:NgZone,
    private userService: UserService,
    public route: Router
  ){
    this.navItems = [
      {
        icon: 'laptop',
        label: 'Dashboard',
        link: 'dashboard'
      },
      {
        icon: 'people',
        label: 'Customers',
        link: 'customer/index'
      },
      {
        icon: 'local_shipping',
        label: 'Techs',
        link: 'tech/index'
      },
      {
        icon: 'calendar_today',
        label: 'Schedule',
        link: 'schedule/index'
      },
      {
        icon: 'content_paste',
        label: 'Work Orders',
        link: 'workorder/index'
      }
    ];

    this.changeNavMode();

    window.onresize = (e) => {
      ngZone.run(() => {
        this.changeNavMode();
      });
    };
  }

  ngOnInit() {
    //
  }

  changeNavMode() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    if(this.width <= 800) {
      this.mode = 'over';
      this.open = 'false';
    }
    if(this.width > 800) {
      this.mode = 'side';
      this.open = 'true';
    }
  }

  logout(){
    this.userService.logout();

    this.route.navigate(['/guest/login']);
  }
}

