<div fxLayout="row" fxLayoutAlign="start center" >
  <h1 class="mr-4">Work Orders</h1>
</div>

<!-- today work orders -->
<div class="outline">
  <h2>Scheduled Today</h2>
  <button
    mat-flat-button
    color="primary"
    (click)="downloadDaysWorkOrders()"
    style="margin-bottom: 10px;"
  >
    Download Todays Workorders
  </button>
  <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start none">
    <!-- no work orders message-->
    <div *ngIf="scheduled.length < 1" class="mb-20">
      No work orders today
    </div>

    <!-- work order list -->
    <ng-container *ngFor="let item of scheduled">
      <div class="workorder-container w-100">
        <mat-card class="tech-work-order"  [ngStyle]="{'border-right-color': getWoPriorityColor(item) }">
          <button mat-button style="float:right">
            <mat-icon inline="true" style="font-size: 25px; margin: 0">local_shipping</mat-icon>
            <br/>
            {{ item.tech.first_name }} {{ item.tech.last_name }}
          </button>
          <mat-card-header style="margin: 0">
            <!-- tech mark complete check mark -->
            <mat-icon *ngIf="item.tech_completed_at" class="text-success wo-complete-icon" aria-hidden="true">done</mat-icon>

            <mat-card-title>{{item.customer.customer_name}}</mat-card-title>
            <mat-card-subtitle>Created: {{item.created_at | date: 'shortDate'}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content *ngIf="item.pivot">
            <p style="margin-left: 16px">
              {{ item.pivot.brand }} | {{ item.pivot.field_name }}
            </p>
            <p style="margin-left: 16px">
              Problem Type: {{ item.problem_type_label }}
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button mat-flat-button color="primary" (click)="openViewWorkorderDetails(item)">Details</button>
          </mat-card-actions>

        </mat-card>
      </div>
    </ng-container>
  </div>
</div>

<!-- unscheduled work orders -->
<div class="outline">
  <h2>Unscheduled</h2>
  <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start none">
    <!-- no work orders message-->
    <div *ngIf="unscheduled.length < 1" class="mb-20">
      No unscheduled work orders
    </div>

    <!-- work order list -->
    <ng-container *ngFor="let item of unscheduled">
      <div class="workorder-container w-100">
        <mat-card class="tech-work-order"  [ngStyle]="{'border-right-color': getWoPriorityColor(item) }">
          <mat-card-header style="margin: 0">
            <!-- tech mark complete check mark -->
            <mat-icon *ngIf="item.tech_completed_at" class="text-success wo-complete-icon" aria-hidden="true">done</mat-icon>

            <mat-card-title>{{item.customer.customer_name}}</mat-card-title>
            <mat-card-subtitle>Created: {{item.created_at | date: 'shortDate'}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content *ngIf="item.pivot">
            <p style="margin-left: 16px">
              {{item.pivot.brand}} | {{item.pivot.field_name}}
            </p>
            <p style="margin-left: 16px">
              Problem Type: {{item.problem_type_label}}
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button mat-flat-button color="primary" (click)="openViewWorkorder(item)">Schedule</button>
            <button mat-raised-button color="primary" (click)="editWorkOrder(item.id)" style="float:right">Edit</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>

<!-- ready to bill work orders -->
<div class="outline">
  <h2>Ready To Bill</h2>
  <!-- no work orders message-->
  <div *ngIf="objectKeys(readyToBill).length < 1" class="mb-20">
    No ready to bill work orders
  </div>

  <!-- work order list -->
  <div *ngFor="let key of objectKeys(readyToBill)">
    <h3> {{ formatDateString(key) }} </h3>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start none">
      <ng-container *ngFor="let item of readyToBill[key]">
        <div class="workorder-container w-100">

          <mat-card class="tech-work-order"  [ngStyle]="{'border-right-color': getWoPriorityColor(item) }">
            <mat-card-header style="margin: 0">
              <!-- tech mark complete check mark -->
              <mat-icon *ngIf="item.tech_completed_at" class="text-success wo-complete-icon" aria-hidden="true">done</mat-icon>

              <mat-card-title>{{ item.customer.customer_name }}</mat-card-title>
              <mat-card-subtitle>Created: {{ formatDateString(item.created_at) }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content *ngIf="item.pivot">
              <p style="margin-left: 16px">
                {{ item.pivot.brand }}
              </p>
              <p style="margin-left: 16px">
                Problem Type: {{ item.problem_type_label}}
              </p>
            </mat-card-content>
            <mat-card-actions>
              <button mat-flat-button color="primary" (click)="openViewWorkorder(item)">Schedule</button>
              <button mat-raised-button color="primary" (click)="editWorkOrder(item.id)" style="float:right">Edit</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- invoiced work orders -->
<div class="outline">
  <h2>Invoiced</h2>

  <!-- work order list -->
  <div>
    <app-invoiced-workorders-index></app-invoiced-workorders-index>
  </div>
</div>
