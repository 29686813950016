import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {NotifierService} from "../../../_services/notifier.service";

@Component({
  selector: 'app-create-workorder-dialog',
  templateUrl: './create-workorder-dialog.component.html',
  styleUrls: ['./create-workorder-dialog.component.scss']
})
export class CreateWorkorderDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
    private notifier: NotifierService
  ) {
    this.notifier.createWorkorderDialog$.subscribe((data:any)=> {
      this.dialogRef.close({success: true});
    })
  }

  ngOnInit(): void {
    //
  }

  onClose() {
    this.dialogRef.close({success: false});
  }

}
