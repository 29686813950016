import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { UserService } from '../_services/user.service';

@Injectable({
  providedIn: 'root'
})
export class SignInGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
  {
    if (this.userService.userIsLoggedIn()) {
      if(this.userService.userIsAdmin()){
        this.router.navigate(['/admin/schedule/index'], {queryParams: {returnUrl: state.url}});
      }else if(this.userService.userIsStaff()){
        this.router.navigate(['/staff/dashboard'], {queryParams: {returnUrl: state.url}});
      }
      return false;
    }
    return true;
  }
}
