import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiService } from '../../../../_services/api.service';
import { UserService } from '../../../../_services/user.service';
import { ConfirmDialogComponent } from '../../../../_dialogs/shared/confirm-dialog/confirm-dialog.component';
import { CreatePivotDialogComponent } from '../../../../_dialogs/shared/create-pivot-dialog/create-pivot-dialog.component';

@Component({
  selector: 'app-customer-show',
  templateUrl: './customer-show.component.html',
  styleUrls: ['./customer-show.component.scss']
})
export class CustomerShowComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  editBaseUrl: string = '';

  customerId: any;
  customer: any = { user: {} };

  pivots: any = [];
  pivotsDataSource: any;
  pivotColumnsToDisplay = ['legal', 'brand', 'mileage', 'field_name', 'actions'];

  form: FormGroup;
  submitted: Boolean = false;

  constructor(
    private api: ApiService,
    private userService: UserService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private _location: Location,
    public dialog: MatDialog
  ) {
    this.customerId = this.route.snapshot.params['id'];
    this.editBaseUrl = (this.userService.userIsAdmin()) ? '/admin/pivot/show' : '/staff/pivot/show'

		this.pivotsDataSource = new MatTableDataSource();

    this.form = this.fb.group({
      customer_name: [{value: '', disabled: false}, [Validators.required]],
      email: [{value: '', disabled: false}, [Validators.required, Validators.email]],
      username: [{value: '', disabled: false}, [Validators.required]],
      password: [{value: '', disabled: false}, []],
    });
  }

  ngOnInit(): void {
    this.getCustomer();
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  getCustomer(){
    let url = `customer/${this.customerId}`;

    this.api.get(url).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'fail'){
        console.log('Get customer failed');
      }else{
        this.customer = data.data;

        this.pivots = this.customer.pivots;
        this.pivotsDataSource.data = this.pivots;
      }
    });
  }

  onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let url = 'customer/' + this.customerId;
    let data = this.form.getRawValue();

    this.api.post(url, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        // clear password
        this.form.controls['password'].setValue('');
        this.openSuccessSnackBar('Saved!');
      }else{
        let errors = '';
        if(data && data.hasOwnProperty('error')){
          for (const x in data.error.data) {
              errors += `${data.error.data[x]}`;
          }
        }else{
          errors = 'An error occurred';
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
      }
    });
  }

  openCreatePivotDialog(){
		const dialogRef = this.dialog.open(CreatePivotDialogComponent, {
			data: {
        customer_id: this.customerId
      },
      width: '600px'
		});
	
		dialogRef.afterClosed().subscribe(result => {
      if(result && result.pivotData){
        this.pivots.push(result.pivotData);
        this.pivotsDataSource.data = this.pivots;
      }
		});
  }

  openErrorSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      panelClass: "error-snack-bar",
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openSuccessSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  goBack(){
    this._location.back();
  }
}
