<div fxLayout="row" fxLayoutAlign="start center" >
  <h1 class="mr-4 w-100">
    {{id ? 'Edit Work Order' : 'Create Work Order'}}
    <!-- loading spinner -->
    <mat-spinner [diameter]="30"
                class="spinner"
                *ngIf="loading || pivotLoading || customerDataLoading">
    </mat-spinner>
  </h1>

</div>
<mat-card class="mat-typography">
<form [formGroup]="form" *ngIf="!loading">
  <div fxLayout="column">
    <!-- customer live search -->
    <div>
      <mat-form-field appearance="fill" style="width: 80%;">
        <mat-label>Customer</mat-label>
        <input type="text" formControlName="customer_name_search" matInput [matAutocomplete]="auto" placeholder="Search...">
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="customerClick($event)">
          <mat-option *ngFor="let option of customers" [value]="option.customer_name">
            {{ option.customer_name }}
          </mat-option>
        </mat-autocomplete>

        <!-- no results text -->
        <div
          class="text-grey mt-10"
          *ngIf="didFirstCustomerSearch && !loadingCustomerSearch && customers.length < 1">
          No results
        </div>

        <!-- loading indicator -->
        <mat-spinner
          [diameter]="30"
          class="customer-search-spinner"
          *ngIf="loadingCustomerSearch">
        </mat-spinner>
      </mat-form-field>

      <!-- customer index button -->
      &nbsp; &nbsp;
      <button
        mat-raised-button
        type="button"
        class="field-add-button"
        color="primary"
        [routerLink]="['../../customer/index']">
        <mat-icon>edit</mat-icon>
      </button>
    </div>

    <ng-conainer *ngIf="selectedCustomerId && customerPivots">
      <!-- pivot select -->
      <span class="text-primary" *ngIf="customerPivots.length === 0">Customer has no Pivots</span>
      <div>
        <mat-form-field appearance="fill" style="width: 80%">
          <mat-label>Select Pivot</mat-label>
          <mat-select formControlName="pivot_id" name="pivot_id">
            <!-- no pivot option -->
            <mat-option [value]="null">No Pivot</mat-option>

            <mat-option *ngFor="let pivot of customerPivots" [value]="pivot.id">
              <ng-container *ngIf="pivot.legal || pivot.brand">
                {{ pivot.field_name ? pivot.field_name + ': ' : '' }} {{ pivot.legal }} {{ pivot.brand ? '- ' + pivot.brand : '' }} {{ pivot.mileage ? ', mileage: ' + pivot.mileage : '' }}
              </ng-container>
              <ng-container *ngIf="!pivot.legal && !pivot.brand">
                {{ pivot.field_name }} {{ pivot.mileage ? ', mileage: ' + pivot.mileage : '' }}
              </ng-container>
            </mat-option>
          </mat-select>
        </mat-form-field>
        &nbsp; &nbsp;
        <button mat-raised-button
          class="field-add-button"
          color="primary"
          [disabled]="!form.get('customer_id').value"
          (click)="openAddPivotDialog()">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <!-- pivot type select -->
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Problem Type</mat-label>
          <mat-select required formControlName="problem_type" name="problem_type">
            <mat-option *ngFor="let problem of options.problem_type_options" [value]="problem.key">
              {{problem.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- description input -->
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Description</mat-label>
          <textarea
            required
            matInput
            rows="10"
            type="text"
            formControlName="description"
            placeholder="Description"></textarea>
        </mat-form-field>
      </div>

      <!-- reporter name -->
      <div>
        <mat-form-field appearance="fill" class="w-100" style="width: 100%">
          <mat-label>Reported By</mat-label>
          <input
            matInput
            type="text"
            formControlName="reporter_name"
            placeholder="Reported By">
        </mat-form-field>
      </div>

      <!-- labor type select -->
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Labor Type</mat-label>
          <mat-select required formControlName="labor_type" name="labor_type">
            <mat-option *ngFor="let type of options.labor_type_options" [value]="type.key">
              {{ type.label }} - {{ type.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- estimate time-->
      <div>
        <mat-form-field appearance="fill" class="w-100" style="width: 100%">
          <mat-label>Estimated Time (hh:mm)</mat-label>
          <input
            matInput
            type="text"
            pattern="[0-9]{2}:[0-9]{2}"
            placeholder="hh:mm"
            formControlName="estimated_time"
            placeholder="Estimated Time">
        </mat-form-field>
      </div>

      <!-- priority select -->
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Priority</mat-label>
          <mat-select formControlName="priority" name="priority">
            <mat-option *ngFor="let status of options?.priority_options" [value]="status.key">
              {{status.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- tech select -->
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Tech</mat-label>
          <mat-select formControlName="tech_id" name="tech_id">
            <mat-option *ngFor="let tech of techniciansList" [value]="tech.tech.id">
              {{tech.first_name}}  {{tech.last_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- date -->
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Scheduled Date</mat-label>
          <input matInput formControlName="scheduled_date" [matDatepicker]="scheduled_date">
          <mat-datepicker-toggle matSuffix [for]="scheduled_date"></mat-datepicker-toggle>
          <mat-datepicker #scheduled_date>
            <mat-datepicker-actions>
              <button mat-button matDatePickerCancel>Cancel</button>
              <button mat-raised-button color="primary" matDatePickerApply>Apply</button>
            </mat-datepicker-actions>
          </mat-datepicker>
        </mat-form-field>
      </div>

      <!-- status select -->
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status" name="status">
            <mat-option *ngFor="let status of options?.statuses" [value]="status.key">
              {{status.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container>
        <!-- road direction-->
        <div>
          <mat-form-field appearance="fill" class="w-100" style="width: 100%">
            <mat-label>Road Direction</mat-label>
            <input
              matInput
              type="text"
              formControlName="road_direction"
              placeholder="Road Direction">
          </mat-form-field>
        </div>

        <!-- Chemical Recently Applied -->
        <div>
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Chemical Recently Applied?</mat-label>
            <mat-select formControlName="chemical_recently_applied" name="chemical_recently_applied">
              <mat-option [value]="1">
                Yes
              </mat-option>
              <mat-option [value]="0">
                No
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- chemical recently applied = Yes inputs -->
        <div *ngIf="form.get('chemical_recently_applied').value == 1">
          <mat-form-field appearance="fill" class="w-100" style="width: 100%">
            <mat-label>Chemical Time</mat-label>
            <input
              matInput
              type="datetime-local"
              formControlName="chemical_datetime"
              placeholder="Chemical Time">
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100" style="width: 100%">
            <mat-label>Chemical Type</mat-label>
            <input
              matInput
              type="text"
              formControlName="chemical_type"
              placeholder="Chemical Type">
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100" style="width: 100%">
            <mat-label>Chemical Reentry Period (If Known)</mat-label>
            <input
              matInput
              type="text"
              formControlName="chemical_reentry_period"
              placeholder="Chemical Reentry Period (If Known)">
          </mat-form-field>
        </div>

        <!-- leave running -->
        <div>
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Leave Running</mat-label>
            <mat-select formControlName="do_leave_running" name="do_leave_running">
              <mat-option [value]="1">
                Yes
              </mat-option>
              <mat-option [value]="0">
                No
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Leave running = Yes inputs -->
        <div *ngIf="form.get('do_leave_running').value == 1">
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Run Direction</mat-label>
            <mat-select formControlName="run_direction" name="run_direction">
              <mat-option *ngFor="let item of options.run_direction_options" [value]="item.key">
                {{ item.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100" style="width: 100%">
            <mat-label>Run Speed</mat-label>
            <input
              matInput
              type="text"
              formControlName="run_speed"
              placeholder="Run Speed">
          </mat-form-field>

          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Run Wet/Dry</mat-label>
            <mat-select formControlName="run_wet_dry" name="run_wet_dry">
              <mat-option *ngFor="let item of options.run_wet_dry_options" [value]="item.key">
                {{ item.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- run with chemical -->
        <div *ngIf="form.get('do_leave_running').value == 1 && form.get('chemical_recently_applied').value == 1">
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Run With Chemical</mat-label>
            <mat-select formControlName="run_with_chemical" name="run_with_chemical">
              <mat-option [value]="1">
                Yes
              </mat-option>
              <mat-option [value]="0">
                No
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </ng-container>

    </ng-conainer>
  </div>

  <!-- submit button -->
  <button type="submit" (click)="onSave()" mat-raised-button color="primary" [disabled]="!form.valid">Save</button>
</form>
</mat-card>
