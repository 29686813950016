import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminAuthGuard } from './_guards/admin-auth.guard';
import { StaffAuthGuard } from './_guards/staff-auth.guard';
import { SignInGuard } from './_guards/sign-in.guard';

import { GuestLayoutComponent } from './layouts/guest-layout/guest-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { StaffLayoutComponent } from './layouts/staff-layout/staff-layout.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'guest/login',
        pathMatch: 'full',
    }, 
    {
        path: 'guest',
        canActivate: [SignInGuard],
        component: GuestLayoutComponent,
        children: [{
            path: '',
            loadChildren: () => import('./layouts/guest-layout/guest-layout.module').then(m => m.GuestLayoutModule),
        }]
    },
    {
        path: 'admin',
        canActivate: [AdminAuthGuard],
        canActivateChild: [AdminAuthGuard],
        component: AdminLayoutComponent,
        children: [{
            path: '',
            loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
        }]
    },
    {
        path: 'staff',
        canActivate: [StaffAuthGuard],
        canActivateChild: [StaffAuthGuard],
        component: StaffLayoutComponent,
        children: [{
            path: '',
            loadChildren: () => import('./layouts/staff-layout/staff-layout.module').then(m => m.StaffLayoutModule),
        }]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AdminAuthGuard, StaffAuthGuard]
})
export class AppRoutingModule { }
