<h2 mat-dialog-title>Available Work Orders <mat-spinner [diameter]="40" style="float:right" *ngIf="loading"></mat-spinner></h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form"> <!--(ngSubmit)="onSave()"-->
    <div fxLayout="column">
      <!-- tech contact info -->
      <div>
        <mat-form-field class="w-100">
          <input
            matInput
            type="text"
            formControlName="search"
            placeholder="Search...">
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="mt-20 overflow-x-auto" style="height: 500px">
    <div fxLayout="row wrap">
      <!-- no work order message-->
      <div *ngIf="unscheduled.length < 1">
        No unscheduled work orders
      </div>

      <!-- list unscheduled work orders -->
      <ng-container *ngFor="let item of unscheduled">
        <mat-card class="tech-work-order mt-10 mb-10"
                  style="width: 30%; cursor: pointer"
                  [ngStyle]="{
                    'background': workorder_id === item.id ? '#adadad' : 'white', 
                    'border-right-color': getWoPriorityColor(item)
                  }"
                  (click)="workorder_id = item.id">
          <mat-card-header style="margin: 0">
            <mat-card-title>
              {{ item.customer.customer_name }}
            </mat-card-title>
            <mat-card-subtitle>Created: {{item.created_at | date: 'shortDate'}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content *ngIf="item.pivot">
            <p style="margin-left: 16px">
              {{item.pivot.brand}} | {{item.pivot.field_name}}
            </p>
            <p style="margin-left: 16px">
              Problem Type: {{item.problem_type ? item.problem_type : 'N/A'}}
            </p>
          </mat-card-content>
        </mat-card>
      </ng-container>

    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <div style="flex-grow: 1;">
    <button mat-raised-button color="warn" [disabled]="!workorder_id || loading" (click)="assignToTech()">Add</button>
  </div>
  <div style="flex-grow: 1; text-align: right;">
    <button mat-raised-button color="warn" (click)="createWorkOrder()">Create</button>
  </div>
</mat-dialog-actions>
