<h2 mat-dialog-title>Create Tech</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="form" (ngSubmit)="onSave()">
        <div fxLayout="column">
            <!-- tech contact info -->
            <div>
                <mat-form-field class="w-100">
                    <input 
                        matInput 
                        type="text"
                        formControlName="first_name"
                        [(ngModel)]="tech.first_name"
                        placeholder="First Name">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-100">
                    <input
                        matInput 
                        type="text"
                        formControlName="last_name"
                        [(ngModel)]="tech.last_name"
                        placeholder="Last Name">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-100">
                    <input 
                        matInput 
                        type="text"
                        formControlName="phone"
                        [(ngModel)]="tech.phone"
                        placeholder="Phone">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-100">
                    <input 
                        matInput 
                        type="text"
                        formControlName="email"
                        [(ngModel)]="tech.email"
                        placeholder="Email">
                </mat-form-field>
            </div>

            <!-- tech login info -->
            <div>
                <mat-form-field class="w-100">
                    <input matInput formControlName="username" type="text" [(ngModel)]="tech.username" placeholder="Username">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-100">
                    <input matInput formControlName="password" type="password" [(ngModel)]="tech.password" placeholder="Password">
                </mat-form-field>
            </div>
        </div>

        <!-- submit button -->
        <button type="submit" mat-raised-button color="primary">Save</button>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onClose()">Close</button>
</mat-dialog-actions>
