<h2 mat-dialog-title>Create Pivot</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="form" (ngSubmit)="onSave()">
        <div fxLayout="column">
            <div>
                <mat-form-field class="w-100">
                    <input matInput formControlName="legal" type="text" [(ngModel)]="pivot.legal" placeholder="Legal">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-100">
                    <input matInput formControlName="brand" type="text" [(ngModel)]="pivot.brand" placeholder="Brand">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-100">
                    <input matInput formControlName="mileage" type="text" [(ngModel)]="pivot.mileage" placeholder="Mileage">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-100">
                    <input matInput formControlName="field_name" type="text" [(ngModel)]="pivot.field_name" placeholder="Field Name">
                </mat-form-field>
            </div>
          <div>
            <mat-form-field class="w-100">
              <input matInput formControlName="latitude" type="text" [(ngModel)]="pivot.latitude" placeholder="Latitude">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="w-100">
              <input matInput formControlName="longitude" type="text" [(ngModel)]="pivot.longitude" placeholder="Longitude">
            </mat-form-field>
          </div>
        </div>

        <!-- submit button -->
        <button type="submit" mat-raised-button color="primary">Save</button>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onClose()">Close</button>
</mat-dialog-actions>
