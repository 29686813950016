import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from './_services/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    protected user: UserService
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = request.clone();
    return next.handle(clonedRequest).pipe(catchError(error => {
        if (error.status === 401 || error.status === 403) {
            this.user.logout();
            return;
        }
        return throwError(error);
    }));
  }
}
