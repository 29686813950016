<h2 mat-dialog-title>Edit Client</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="form" (ngSubmit)="onSave()">
        <div fxLayout="column">
            <div>
                <mat-form-field class="w-100">
                    <input matInput formControlName="customer_name" name="customer_name" type="text" placeholder="Customer Name">
                </mat-form-field>
            </div>
            <!--<div>
                <mat-form-field class="w-100">
                    <input matInput formControlName="email" name="email" type="email" placeholder="Email">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-100">
                    <input matInput formControlName="username" name="username" type="text" placeholder="Username">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-100">
                    <input matInput formControlName="password" name="password" type="password" placeholder="Password">
                </mat-form-field>
            </div>-->
        </div>

        <!-- submit button -->
        <button type="submit" mat-raised-button color="primary">Save</button>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onClose()">Close</button>
</mat-dialog-actions>
