<h1>Tech</h1>

<div class="mb-20">
    <button type="button" mat-raised-button color="defualt" (click)="goBack()">
        <mat-icon class="sidenav-icon">keyboard_arrow_left</mat-icon> back
    </button>
</div>

<!-- form -->
<mat-card class="mat-typography">
    <form [formGroup]="form" (ngSubmit)="onSave()" class="form-input-padding">
        <div fxLayout="row" fxLayout.lt-md="column">
            <div fxFlex="33" fxFlex.lt-md="100">
                <!-- tech contact info -->
                <div>
                    <mat-form-field class="w-100">
                        <input 
                            matInput 
                            type="text"
                            formControlName="first_name"
                            [(ngModel)]="tech.user.first_name"
                            placeholder="First Name">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w-100">
                        <input
                            matInput 
                            type="text"
                            formControlName="last_name"
                            [(ngModel)]="tech.user.last_name"
                            placeholder="Last Name">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w-100">
                        <input 
                            matInput 
                            type="text"
                            formControlName="phone"
                            [(ngModel)]="tech.phone"
                            placeholder="Phone">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w-100">
                        <input 
                            matInput 
                            type="text"
                            formControlName="email"
                            [(ngModel)]="tech.user.email"
                            placeholder="Email">
                    </mat-form-field>
                </div>

                <!-- tech login info -->
                <div>
                    <mat-form-field class="w-100">
                        <input 
                            matInput 
                            type="text"
                            formControlName="username"
                            [(ngModel)]="tech.user.username"
                            placeholder="Username">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w-100">
                        <input 
                            matInput 
                            type="password"
                            formControlName="password"
                            [(ngModel)]="tech.user.password"
                            placeholder="Password">
                    </mat-form-field>
                </div>
    
                <div>
                    <!-- submit button -->
                    <button type="submit" mat-raised-button color="primary">Save</button>

                    <!-- delete button -->
                    <button 
                        class="float-right ml-10"
                        type="button"
                        mat-raised-button
                        color="danger"
                        *ngIf="!tech.set_inactive_at"
                        (click)="openConfirmDelete()">
                        Delete
                    </button>

                    <!-- deactivate button -->
                    <button 
                        class="float-right"
                        type="button"
                        mat-raised-button
                        color="warn"
                        *ngIf="tech.set_inactive_at"
                        (click)="submitActivate()">
                        Inactive
                    </button>
                    <!-- activate button -->
                    <button 
                        class="float-right"
                        type="button"
                        mat-raised-button
                        color="success"
                        *ngIf="!tech.set_inactive_at"
                        (click)="openConfirmDeactivate()">
                        Active
                    </button>
                </div>
            </div>
        </div>
    </form>
</mat-card>
