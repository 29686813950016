import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { MatDialog } from '@angular/material/dialog';

import { Observable, BehaviorSubject, of, merge, fromEvent, } from 'rxjs';
import { catchError, finalize, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { CreateCustomerDialogComponent } from "../../../../_dialogs/shared/create-customer-dialog/create-customer-dialog.component";
import { CustomerInterface } from '../../../../_interfaces/customer.interface';
import { ApiService } from '../../../../_services/api.service';
import { UserService } from '../../../../_services/user.service';

@Component({
  selector: 'app-customer-index',
  templateUrl: './customer-index.component.html',
  styleUrls: ['./customer-index.component.scss']
})
export class CustomerIndexComponent implements OnInit {
  customerInfo: any;
  displayedColumns: string[] = ['customer_name', 'username', 'actions'];
  dataSource: CustomerDataSource;

  editBaseUrl: string = '';

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit() {
    this.customerInfo = this.route.snapshot.data["customerInfo"];
    this.dataSource = new CustomerDataSource(this.apiService);
    this.dataSource.loadCustomer('', '', '', 0, 10);

    this.editBaseUrl = (this.userService.userIsAdmin()) ? '/admin/customer/show' : '/staff/customer/show'
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent(this.input.nativeElement, 'keyup')
    .pipe(
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.paginator.pageIndex = 0;
        this.loadCustomerPage();
      })
    )
    .subscribe();

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
      tap(() => this.loadCustomerPage())
    )
    .subscribe();
  }

  loadCustomerPage() {
    this.dataSource.loadCustomer(
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
  }

  refreshCustomer(){
    let url = 'customer/total';
    this.apiService.get(url).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        this.customerInfo = data.data;
        this.loadCustomerPage();
      }else{
        console.log('An error occurred retriving customer info data');
      }
    });
  }

  openCreateModal(){
    const dialogRef = this.dialog.open(CreateCustomerDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.customerId){
        // switch to edit page
        this.router.navigate(['/admin/customer/show', result.customerId]);
      }
    });
  }
}

class CustomerDataSource implements DataSource<CustomerInterface> {
  private customerSubject = new BehaviorSubject<CustomerInterface[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<CustomerInterface[]> {
      return this.customerSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
      this.customerSubject.complete();
      this.customerSubject.complete();
  }

  loadCustomer(filter = '', sortDirection = 'asc', sortActive='', pageIndex = 0, pageSize = 5) {
    this.loadingSubject.next(true);

    let url = 'customer/search';
    
    let data = {
      filter: filter,
      sort_direction: sortDirection,
      sort_active: sortActive,
      page_index: pageIndex,
      page_size: pageSize,
    };

    this.apiService.searchCustomer(url, data).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
    .subscribe(customer => {
      this.customerSubject.next(customer)
    });
  }   
}