import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';

import { Location } from '@angular/common';

import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiService } from '../../../../_services/api.service';
import { UserService } from '../../../../_services/user.service';
import { ConfirmDialogComponent } from '../../../../_dialogs/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-tech-show',
  templateUrl: './tech-show.component.html',
  styleUrls: ['./tech-show.component.scss']
})
export class TechShowComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  editBaseUrl: string = '';

  techId: any;
  tech: any = { user: {} };

  form: FormGroup;
  submitted: Boolean = false;

  constructor(
    private api: ApiService,
    private userService: UserService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private _location: Location,
    public dialog: MatDialog
  ) {
    this.techId = this.route.snapshot.params['id'];

    this.form = this.fb.group({
      first_name: [{value: '', disabled: false}, []],
      last_name: [{value: '', disabled: false}, []],
      phone: [{value: '', disabled: false}, []],
      email: [{value: '', disabled: false}, [Validators.required]],
      username: [{value: '', disabled: false}, [Validators.required]],
      password: [{value: '', disabled: false}, []],
    });
  }

  ngOnInit(): void {
    this.getTech();
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  getTech(){
    let url = `tech/${this.techId}`;

    this.api.get(url).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'fail'){
        console.log('get tech failed');
      }else{
        this.tech = data.data;
      }
    });
  }

  onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let url = 'tech/' + this.techId;
    let data = this.form.getRawValue();

    this.api.post(url, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        // clear password
        this.form.controls['password'].setValue('');
        this.openSuccessSnackBar('Saved!');
      }else{
        let errors = '';
        if(data && data.hasOwnProperty('error')){
          for (const x in data.error.data) {
              errors += `${data.error.data[x]}`;
          }
        }else{
          errors = 'An error occurred';
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
      }
    });
  }

  submitDeactivate(){
    let url = `tech/${this.techId}/set-inactive`;
    let data = {};

    this.api.post(url, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        this.openSuccessSnackBar('Tech inactive!');
        this.tech.set_inactive_at = 1;
        // this.goBack();
      }else{
        let errors = '';
        if(data && data.hasOwnProperty('error')){
          for (const x in data.error.data) {
              errors += `${data.error.data[x]}`;
          }
        }else{
          errors = 'An error occurred';
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
      }
    });
  }

  submitActivate(){
    let url = `tech/${this.techId}/set-active`;
    let data = {};

    this.api.post(url, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        this.openSuccessSnackBar('Tech active!');
        this.tech.set_inactive_at = 0;
        // this.goBack();
      }else{
        let errors = '';
        if(data && data.hasOwnProperty('error')){
          for (const x in data.error.data) {
              errors += `${data.error.data[x]}`;
          }
        }else{
          errors = 'An error occurred';
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
      }
    });
  }

  submitDelete(){
    let url = `tech/${this.techId}/delete`;
    let data = {
      _method: 'DELETE'
    };

    this.api.post(url, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        this.openSuccessSnackBar('Tech deleted!');
        this.goBack();
      }else{
        let errors = '';
        if(data && data.hasOwnProperty('error')){
          for (const x in data.error.data) {
              errors += `${data.error.data[x]}`;
          }
        }else{
          errors = 'An error occurred';
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
      }
    });
  }

  openErrorSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      panelClass: "error-snack-bar",
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openSuccessSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openConfirmDeactivate(): void {
    const _message = 'Are you sure you want to make this tech inactive? This tech will not be able log in while inactive.';

    const dialogData = {
      title: "Make Tech Inactive",
      message: _message
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.submitDeactivate();
      }
    });
  }

  openConfirmDelete(): void {
    const _message = 'Are you sure you want to delete this tech? This may have unintended effects for work orders assigned to this tech.';

    const dialogData = {
      title: "Delete Tech",
      message: _message
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.submitDelete();
      }
    });
  }

  goBack(){
    this._location.back();
  }
}
