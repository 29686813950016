<h2 mat-dialog-title>Create Client</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="form" (ngSubmit)="onSave()">
        <div fxLayout="column">
            <div>
                <mat-form-field class="w-100">
                    <input matInput formControlName="customer_name" type="text" [(ngModel)]="customer.customer_name" placeholder="Customer Name">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-100">
                    <input matInput formControlName="email" type="email" [(ngModel)]="customer.email" placeholder="Email">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-100">
                    <input matInput formControlName="username" type="text" [(ngModel)]="customer.username" placeholder="Username">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-100">
                    <input matInput formControlName="password" type="password" [(ngModel)]="customer.password" placeholder="Password">
                </mat-form-field>
            </div>
        </div>

        <!-- submit button -->
        <button type="submit" mat-raised-button color="primary">Save</button>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onClose()">Close</button>
</mat-dialog-actions>
