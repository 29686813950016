import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';

import { MatDialog} from "@angular/material/dialog";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

import { ApiService } from '../../../../_services/api.service';

import * as moment from 'moment';

import { NotifierService } from "../../../../_services/notifier.service";
import { AddWorkOrderDialogComponent } from "../../../../_dialogs/shared/add-work-order-dialog/add-work-order-dialog.component";
import { ViewWorkorderDialogComponent } from "../../../../_dialogs/shared/view-workorder-dialog/view-workorder-dialog.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { isSameDay, isSameMonth, startOfDay, addDays } from 'date-fns';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { ErrorStateMatcher } from '@angular/material/core';

export class WorkorderErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-schedule-index',
  templateUrl: './schedule-index.component.html',
  styleUrls: ['./schedule-index.component.scss']
})
export class ScheduleIndexComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  searchFormControl = new FormControl('');
  matcher = new WorkorderErrorStateMatcher();
  techniciansList = [];
  technicianFormControl = new FormControl('');
  searchResults = new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  loading: boolean = false;
  isLoading: boolean = false;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  dragToCreateActive = false;
  viewDate = new Date();
  weekStartsOn: 0 = 0;
  clickedDate: Date;
  clickedColumn: number;
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = true;
  totalEvents: number;
  startWeek: string;
  endWeek: string;

  schedule: any = [];
  selectedDate: any = '';
  form: FormGroup;
  unscheduled: any = [];

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh = new Subject<void>();

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private notifier: NotifierService,
    private modal: NgbModal,
  ) {
    this.notifier.createWorkorderDialog$.subscribe((data:any)=> {
      this.getSchedule();
    })

    let defaultDate = moment().toDate();

    this.form = this.fb.group({
      selectedDate: [defaultDate, [Validators.required]],
    });

    this.form.get('selectedDate').valueChanges.subscribe((data)=> {
      this.getSchedule();
    })
  }

  ngOnInit(): void {
    this.fetchTechs();
    this.initData();
  }

  ngAfterViewInit() {
    this.searchResults.paginator = this.paginator;
  }

  async initData(){
    this.getSchedule();
  }

  loadCalendar() {
    this.loading = true;
    let url = `workorder/index`;
    this.events = [];
    this.api.get(url).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((response: any) => {
      if (response.status === 'fail'){
        console.log('Get schedule index failed');
      }else{
        response.data.forEach(workorder => {
          let event = {
            start: addDays(startOfDay(new Date(workorder.scheduled_date)), 1), // Objects that start at midnight display on the prior day for some reason...
            title: workorder.description,
            meta: workorder,
          }

          if (workorder.priority_info) {
            event['color'] = {
              primary: workorder.priority_info.color,
              secondary: this.shadeColor(workorder.priority_info.color, 20),
            }
          }

          this.events.push(event);
        });
      }
      this.loading = false;
    });
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.openViewWorkorder(event.meta);
  }

  fetchTechs() {
    let url = `tech`;
    this.isLoading = true;

    this.api.get(url).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe(
      (response: any) => {
        if (response.status !== 'success') {
          console.log('Failed to fetch techs')
          return;
        }

        this.techniciansList = response.data;
      },
      (error: any) => {
        console.log(error);
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  getTechNameDisplay(tech){
    let nameStr = tech.first_name;

    if(tech.last_name){
      let lastName = tech.last_name.charAt(0);
      lastName.toUpperCase();
      nameStr = nameStr + ' ' + lastName + '.';
    }

    return nameStr;
  }


  searchOptions = {}
  handleTechnicianSearch() {
    if (this.technicianFormControl.value.length > 0) {
      this.searchOptions['techs'] = this.technicianFormControl.value;
      this.searchWorkorders(this.searchOptions);
    } else {
      delete this.searchOptions['techs'];
      if (typeof this.searchOptions['customer'] === 'undefined') {
        this.searchResults = new MatTableDataSource<any>();
      }
    }
  }
  flexSearch() {
    if (this.searchFormControl.value.length >= 3) {
      this.searchOptions['searchString'] = this.searchFormControl.value;
      this.searchWorkorders(this.searchOptions);
    } else {
      delete this.searchOptions['searchString'];
      if (typeof this.searchOptions['techs'] === 'undefined') {
        this.searchResults = new MatTableDataSource<any>();
      }
    }
  }
  searchWorkorders(options: {}) {
    let url = `admin/workorder/search`;

    this.api.get(url, options).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((response: any) => {
      if (response.status === 'fail'){
        console.log('Search schedule failed');
      }else{
        this.searchResults.data = response.data;
        console.log("search results", response.data);
      }
      this.loading = false;
    });
  }

  // customerSearch() { // Works in tandem with tech search only.
  //   if (this.searchFormControl.value.length >= 3) {
  //     this.searchOptions['customer'] = this.searchFormControl.value;
  //     this.searchWorkorders(this.searchOptions);
  //   } else {
  //     delete this.searchOptions['customer'];
  //     if (typeof this.searchOptions['techs'] === 'undefined') {
  //       this.searchResults = new MatTableDataSource<any>();
  //     }
  //   }
  // }

  // searchWorkordersWithSpecificOptions(options: {}) {
  //   let url = `workorder/index`;

  //   this.api.get(url, options).pipe(catchError((err: any) => {
  //     return of(err);
  //   })).subscribe((response: any) => {
  //     if (response.status === 'fail'){
  //       console.log('Search schedule failed');
  //     }else{
  //       this.searchResults.data = response.data;
  //       // console.log("search results", response.data);
  //     }
  //     this.loading = false;
  //   });
  // }

  displayedColumns = ['customer', 'scheduledDate', 'status', 'tech', 'action'];

  clearSearchResults() {
    this.searchResults = new MatTableDataSource<any>();
    this.searchFormControl.reset();
    this.technicianFormControl.reset();
  }

  incrementDate(state) {
    const date = this.form.get('selectedDate').value;
    let newDate = this.form.get('selectedDate').value;

    if (state === 'forward') {
      newDate = moment(date).add(1, 'days').toDate();
    } else {
      newDate = moment(date).subtract(1, 'days').toDate();
    }

    this.form.get('selectedDate').patchValue(newDate)
  }

  getSchedule(){
    this.loading = true;

    let date = this.form.get('selectedDate').value;

    const selectedDate = moment(date).format('YYYY-MM-DD');

    let data = {
      date: selectedDate
    };

    let url = `workorder/schedule`;

    this.api.get(url, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'fail'){
        console.log('Get schedule failed');
      }else{
        this.schedule = data.data;
        this.loadCalendar();
      }
    });
  }

  getWoPriorityColor(wo){
    if(wo.priority_info){
      return wo.priority_info.color;
    }else{
      return '#ffffff';
    }
  }

  shadeColor(color, percent: number) {
      if (color.toLowerCase() === '#ffffff'){
        return '#ffffff';
      }
      if (color === '#000000') {
        return '#000000';
      }

      let R: number = parseInt(color.substring(1,3),16);
      let G: number = parseInt(color.substring(3,5),16);
      let B: number = parseInt(color.substring(5,7),16);

      R = R * (100 + percent) / 100;
      G = G * (100 + percent) / 100;
      B = B * (100 + percent) / 100;

      R = (R<255)?R:255;
      G = (G<255)?G:255;
      B = (B<255)?B:255;

      var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
      var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
      var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

      return "#"+RR+GG+BB;
  }

  assignToTech(workorder_id, tech_id) {
    this.api.post(`workorder/${workorder_id}/schedule`, {tech_id}).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'fail'){
        console.log('Failed to assign to tech');
      }else{
        this.openSuccessSnackBar('Success, work order successfully assigned.')
      }
    });
  }

  openSuccessSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openErrorSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      panelClass: "error-snack-bar",
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  drop(event: CdkDragDrop<any>, tech_id: string) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.assignToTech(event.container.data[0].id, tech_id);
    }
  }

  openViewWorkorder(item) {
    const dialogRef = this.dialog.open(ViewWorkorderDialogComponent, {
      width: '800px',
      data: {
        schedule: false,
        ...item
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.success){
        this.loadCalendar();
        this.getSchedule();
      }
    });
  }

  openAssignmentModal(tech_id) {
    const dialogRef = this.dialog.open(AddWorkOrderDialogComponent, {
      width: '800px',
      data: {
        tech_id,
        scheduled_date: moment(this.form.get('selectedDate').value).format('YYYY-MM-DD')
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.success){
        this.loadCalendar();
        this.getSchedule();
      }
    });
  }
}
