<div fxLayout="row" fxLayout.lt-md="column">
  <div fxFlex="33" fxFlex.lt-md="100">
  </div>
  <div fxFlex="33" fxFlex.lt-md="100">
    <h2 mat-dialog-title class="w-100 text-center">Work Order Details</h2>
  </div>

  <!-- large screen display -->
  <div fxFlex="33" fxFlex.lt-md="100" fxShow.lt-md="false">
    <div class="text-right" *ngIf="workorder.ready_to_bill || (workorder.quickbook_status && workorder.quickbook_status.qb_status == 'Error')">
      <button mat-raised-button color="success" (click)="submitSendToQuickbooks()">Send to QB</button>
    </div>
  </div>

    <!-- small screen display -->
    <div fxFlex="33" fxFlex.lt-md="100" fxShow="false" fxShow.lt-md="true">
      <div class="text-center mb-10" *ngIf="workorder.ready_to_bill || (workorder.quickbook_status && workorder.quickbook_status.qb_status == 'Error')">
        <button mat-raised-button color="success" (click)="submitSendToQuickbooks()">Send to QB</button>
      </div>
    </div>
</div>

<mat-dialog-content class="mat-typography">
  <!-- header info -->
  <div>
    <h2 class="w-100 text-center" mat-dialog-title>{{workorder.customer.customer_name}}</h2>
    <h4 class="w-100 text-center">Created At: {{workorder.created_at | date: 'short'}}</h4>
    <h4 class="w-100 text-center">
      Pivot:
      {{ workorder.pivot ? workorder.pivot.brand + ' - ' + workorder.pivot.legal : 'N/A' }}
    </h4>
  </div>

  <!-- work order info -->
  <div class="mt-10 mb-10" style="background: #f5f5f5; padding: 20px">
    <!-- work order info -->
    <h3 class="mt-10 text-center w-100">Work Order Info</h3>
    <div fxLayout="row" fxLayout.lt-md="column">
      <div fxFlex="33" fxFlex.lt-md="100">
        <p><span class="bold">Problem Type:</span> {{ workorder.problem_type_label }}</p>
        <p>
          <span class="bold">Reported By: </span>{{ workorder.priority_info ? workorder.priority_info.label : 'N/A' }}
        </p>
      </div>
      <div fxFlex="33" fxFlex.lt-md="100">
        <p>
          <span class="bold">Priority: </span>{{ workorder.priority_info ? workorder.priority_info.label : 'N/A' }}
        </p>
        <p>
          <span class="bold">Tech Completed: </span>{{ workorder.tech_completed_at ? formatDateString(workorder.tech_completed_at) : 'N/A' }}
          <!-- tech mark complete check mark -->
          <mat-icon *ngIf="workorder.tech_completed_at" class="text-success va-middle" aria-hidden="true">done</mat-icon>
        </p>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column">
        <p>
          <span class="bold">Status: </span>{{(workorder.status).toUpperCase()}}
          <span *ngIf="workorder.status === 'scheduled'">
            - ({{ workorder.scheduled_date | date: 'short' }})
          </span>
        </p>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
      <div fxFlex="100" fxFlex.lt-md="100">
        <p><span class="bold">Description:</span> {{ workorder.description }}</p>
      </div>
    </div>

    <hr>

    <!-- Quickbooks info -->
    <div class="mb-10" style="background: #f5f5f5;" *ngIf="workorder.quickbook_status">
      <h3 class="mt-10 text-center w-100">Quickbooks Send Details</h3>
      <div fxLayout="row" fxLayout.lt-md="column">
        <div fxFlex="33" fxFlex.lt-md="100">
          <p><span class="bold">Send status:</span> {{ workorder.quickbook_status.qb_status }}</p>
          <p *ngIf="workorder.quickbook_status.log_message">
            <span class="bold">Error message: <pre>{{ workorder.quickbook_status.log_message }}</pre></span>
          </p>
        </div>
      </div>
    </div>

    <hr>

    <!-- additional info -->
    <h3 class="mt-10 text-center w-100">Additional Info</h3>
    <div fxLayout="row" fxLayout.lt-md="column">
      <div fxFlex="33" fxFlex.lt-md="100">
        <p><span class="bold">Reported By:</span> {{workorder.reporter_name ? workorder.reporter_name : 'N/A'}}</p>
      </div>
      <div fxFlex="33" fxFlex.lt-md="100">
        <p><span class="bold">Leave Running:</span> {{workorder.do_leave_running ? 'Yes' : 'No'}}</p>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
      <div fxFlex="33" fxFlex.lt-md="100">
        <p><span class="bold">Recent Chemical?</span> {{workorder.chemical_recently_applied ? 'Yes' : 'No'}}</p>
      </div>
      <div fxFlex="33" fxFlex.lt-md="100">
        <p><span class="bold">Chem. Type:</span> {{workorder.chemical_type ? workorder.chemical_type : 'N/A'}}</p>
      </div>
      <div fxFlex="33" fxFlex.lt-md="100">
        <p>
          <span class="bold">Chem. Time:</span>
          {{ workorder.chemical_datetime ? (workorder.chemical_datetime + 'Z' | date:'short') : 'N/A' }}
        </p>
      </div>
    </div>
  </div>


  <!-- buttons -->
  <div class="mb-20">
    <div fxLayout="row">
      <div fxFlex="50" fxFlex.lt-md="50">
        <!-- edit button -->
        <button mat-raised-button class="mr-10" color="warn" (click)="goToEditWorkOrder(workorder.id)" *ngIf="!allowSchedule">Edit</button>

        <button mat-raised-button color="warn" (click)="goToEditWorkOrderPdf(workorder.id)">PDF</button>
      </div>
      <div fxFlex="50" fxFlex.lt-md="50" class="text-right">
        <!-- unschudule button -->
        <button mat-raised-button color="warn" (click)="submitUnschedule()" [disabled]="!form.valid" *ngIf="!allowSchedule && !invoiced">
          Unschedule
        </button>
      </div>
    </div>
  </div>

  <!-- form -->
  <div class="mt-20 w-100" style="min-height: 300px">
    <form [formGroup]="form" style="margin: 0 auto">
      <div fxLayout="row" fxLayout.lt-md="column">
        <div fxFlex="33" fxFlex.lt-md="100">
          <div *ngIf="allowSchedule || true" class="mr-10">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Status</mat-label>
              <mat-select
                [disabled]="invoiced"
                formControlName="status"
                name="status">
                <mat-option *ngFor="let status of options?.statuses" [value]="status.key">
                  {{status.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxFlex="33" fxFlex.lt-md="100">
          <div *ngIf="allowSchedule || true" class="mr-10">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Schedule Tech</mat-label>
              <mat-select
                [disabled]="invoiced"
                formControlName="tech_id"
                name="tech_id">
                <mat-option *ngFor="let tech of techs" [value]="tech.id">
                  {{tech.user.first_name}} {{tech.user.last_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxFlex="33" fxFlex.lt-md="100">
          <div class="mr-10">
            <mat-form-field *ngIf="allowSchedule || true" class="example-full-width" appearance="fill" class="w-100">
              <mat-label>Choose a date</mat-label>
              <input
                [disabled]="invoiced"
                matInput
                [matDatepicker]="picker"
                formControlName="scheduled_date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- additional tech -->
      <div fxLayout="row" fxLayout.lt-md="column">
        <div fxFlex="100" fxFlex.lt-md="100">
          <div class="mr-10">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Additional Techs</mat-label>
              <mat-select
                [disabled]="invoiced"
                formControlName="additional_tech_id"
                name="additional_tech_id"
                multiple>
                <mat-option *ngFor="let tech of techs" [value]="tech.id">
                  {{ tech.user.first_name }} {{ tech.user.last_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- comments -->
      <div fxLayout="row" fxLayout.lt-md="column">
        <div fxFlex="100" fxFlex.lt-md="100">
          <div class="mr-10">
            <mat-form-field  appearance="fill" class="w-100">
              <mat-label>Comments</mat-label>
              <textarea
                matInput
                rows="4"
                formControlName="comments"
                name="comments"
                placeholder="...">
              </textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="mt-20 mb-20">
        <!-- schedule button -->
        <button mat-raised-button color="warn" (click)="submitSave()" [disabled]="!form.valid" *ngIf="!allowSchedule">
          Save
        </button>
      </div>
    </form>

    <div class="mt-10 mb-20">
      <div fxLayout="row" fxLayout.lt-md="column">
        <!-- labor times -->
        <div fxFlex="50" fxFlex.lt-md="100">
          <div class="pr-10">
            <p class="bold">Labor Times:</p>

            <!-- no labor times message -->
            <p *ngIf="workorder.labor_times.length < 1">
              No labor times
            </p>

            <!-- list labor times -->
            <table *ngFor="let date of workorder.labor_times_grouped | keyvalue;" class="fs-16">
              <tr>
                <td colspan="3">
                  {{ formatDateString(date.key) }}
                </td>
              </tr>

              <tr *ngFor="let time of workorder.labor_times_grouped[date.key]">
                <td style="width: 10px;"></td>
                <td class="text-grey">
                  <span *ngIf="time.tech">{{ time.tech.user.first_name }} {{ time.tech.user.last_name }}: </span>{{ formatLaborTime(time.labor_time) }}
                </td>
                <td *ngIf="!invoiced">
                  <!-- delete labor time button -->
                  <button
                    mat-icon-button
                    color="warn"
                    *ngIf="!time.show_confirm_remove"
                    (click)="showConfirmRemoveItem(time)">
                    <mat-icon>close</mat-icon>
                  </button>

                  <!-- delete labor time button -->
                  <button
                    mat-stroked-button
                    *ngIf="time.show_confirm_remove"
                    color="warn"
                    (click)="deleteLaborTime(time)">
                    Remove?
                  </button>
                </td>
              </tr>
            </table>

            <!-- add new time form -->
            <div class="mt-10" *ngIf="!invoiced">
              <p class="bold">Add Labor Time</p>
              <mat-form-field appearance="fill" class="w-100" style="width: 100%">
                <mat-label>Start Time</mat-label>
                <input
                  matInput
                  type="time"
                  [(ngModel)]="newLaborTime.start_time"
                  placeholder="">
              </mat-form-field>

              <mat-form-field appearance="fill" class="w-100" style="width: 100%">
                <mat-label>End Time</mat-label>
                <input
                  matInput
                  type="time"
                  [(ngModel)]="newLaborTime.end_time"
                  placeholder="">
              </mat-form-field>

              <!-- save labor time button -->
              <button
                mat-raised-button
                color="warn"
                (click)="submitLaborTime()"
                [disabled]="!hasValidNewLaborTime()">
                Add Time
              </button>
            </div>
          </div>
        </div>

        <!-- used parts -->
        <div fxFlex="50" fxFlex.lt-md="100">
          <p class="bold">Used Parts:</p>

          <!-- no used parts message -->
          <p *ngIf="workorder.used_parts.length < 1">
            No used parts
          </p>

          <!-- list used parts -->
          <table>
            <tr *ngFor="let part of workorder.used_parts" class="fs-16">
              <td class="pr-10">
                <div>
                  {{ part.work_order_part.description }}
                </div>
                <div>
                  <small>{{ part.work_order_part.label }}</small>
                </div>
              </td>
              <td style="min-width: 50px;">
                x {{ part.used_count }}
              </td>
              <td *ngIf="!invoiced">
                <!-- delete used part button -->
                <button
                  mat-icon-button
                  color="warn"
                  *ngIf="!part.show_confirm_remove"
                  (click)="showConfirmRemoveItem(part)">
                  <mat-icon>close</mat-icon>
                </button>

                <!-- delete used part button -->
                <button
                  mat-stroked-button
                  *ngIf="part.show_confirm_remove"
                  color="warn"
                  (click)="deleteUsedPart(part)">
                  Remove?
                </button>
              </td>
            </tr>
          </table>

          <!-- add part time -->
          <div class="mt-10" *ngIf="!invoiced">
            <p class="bold">Add Used Part</p>

            <!-- parts live search -->
            <form [formGroup]="newPartForm">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Search Parts</mat-label>
                <input type="text" formControlName="parts_search" matInput [matAutocomplete]="auto" placeholder="Search...">
                <mat-autocomplete
                  #auto="matAutocomplete"
                  (optionSelected)="newPartClick($event)">
                  <mat-option *ngFor="let option of parts" [value]="option.description">
                    {{ option.description }} {{ option.label }}
                  </mat-option>
                </mat-autocomplete>

                <!-- no results text -->
                <div
                  class="text-grey mt-10"
                  *ngIf="didFirstPartsSearch && !loadingPartsSearch && parts.length < 1">
                  No results
                </div>

                <!-- loading indicator -->
                <mat-spinner
                  [diameter]="30"
                  class="customer-search-spinner"
                  *ngIf="loadingPartsSearch">
                </mat-spinner>
              </mat-form-field>
            </form>

            <!-- add part count -->
            <div *ngIf="selectedPart" class="mb-10">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Qty</mat-label>
                <input
                  matInput
                  type="number"
                  min="0"
                  [(ngModel)]="newPart.count"
                  placeholder="">
              </mat-form-field>

              <!-- save labor time button -->
              <button
                mat-raised-button
                color="warn"
                (click)="submitUsedPart()"
                [disabled]="newPart.count < 1">
                Add Part
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div style="flex-grow: 1;">
    <!-- schudule button -->
    <button mat-raised-button color="warn" (click)="submitSave()" [disabled]="!form.valid" *ngIf="allowSchedule">
      Schedule Work Order
    </button>
  </div>
  <div style="flex-grow: 1; text-align: right;" *ngIf="!invoiced">
    <!-- <button mat-stroked-button color="warn" (click)="cancel()">Close</button> -->
    <button mat-stroked-button color="warn" *ngIf="!doShowConfirmDelete" (click)="showConfirmDelete()">Delete</button>
    <button mat-stroked-button color="warn" *ngIf="doShowConfirmDelete" (click)="onSubmitDelete()">Confirm Delete?</button>
  </div>
</mat-dialog-actions>

