import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Location } from "@angular/common";

import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

import { CreatePivotDialogComponent } from "../../../../_dialogs/shared/create-pivot-dialog/create-pivot-dialog.component";
import { NotifierService } from "../../../../_services/notifier.service";

import { ApiService } from "../../../../_services/api.service";

import * as moment from 'moment';

import { Subscription } from "rxjs";
import { filter, distinctUntilChanged, switchMap, debounceTime } from "rxjs/operators";
import { catchError, map, startWith } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-create-workorder',
  templateUrl: './create-workorder.component.html',
  styleUrls: ['./create-workorder.component.scss']
})
export class CreateWorkorderComponent implements OnInit {
  @Input()
  isDialog = false;
  @Input()
  dialogData = null;

  // debounce observable guide:
  // https://www.tektutorialshub.com/angular/debouncetime-debounce-in-angular/
  customerSearchObservable: Subscription;
  loadingCustomerSearch = false;
  didFirstCustomerSearch = false;

  customerDataLoading = false;
  id: string = null;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  workOrder: any = {};

  selectedCustomer: any = '';
  selectedCustomerId: any = '';
  customers: any = [];
  customerPivots: any = [];
  techniciansList: any = [];

  options: any = {};
  loading = true;
  pivotLoading = false;
  form: FormGroup;

  constructor(
    private _snackBar: MatSnackBar,
    private api: ApiService,
    private fb: FormBuilder,
    private _loaction: Location,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private notifier: NotifierService
  ) {
    // get work order id from url, if there is any
    this.route.paramMap.subscribe((data: any) => {
      if (data.params.workorder_id) {
        return this.id = data.params.workorder_id;
      }
    });

    // init form
    this.form = this.fb.group({
      customer_name_search: ['', []],
      customer_id: ['', [Validators.required]],
      pivot_id: ['', []],
      reporter_name: ['', []],
      problem_type: ['', [Validators.required]],
      description: ['', [Validators.required]],
      status: [''],
      priority: [''],
      tech_id: [''],
      scheduled_date: [''],
      estimated_time: [''],
      labor_type: ['', [Validators.required]],
      road_direction: [''],
      chemical_recently_applied: [''],
      chemical_date: [''],
      chemical_time: [''],
      chemical_datetime: [''],
      chemical_type: [''],
      chemical_reentry_period: [''],
      do_leave_running: [''],
      run_direction: [''],
      run_speed: [''],
      run_wet_dry: [''],
      run_with_chemical: [''],
    });
  }

  async ngOnInit() {
    this.loading = true;

    // get customers and form select options
    await this.getOptions();

    this.initCustomerSearchObservable();

    // if id provided in route get work order for edit form
    if (this.id) {
      await this.getWorkOrder(this.id);
      await this.getCustomerPivots(this.workOrder.customer_id);
      await this.searchCustomers(this.workOrder.customer.customer_name);

      this.patchWorkorder();
    }

    this.fetchTechs();
    this.addScheduleDateValidationOnTechInput();
    this.loading = false;
  }

  initCustomerSearchObservable(){
    // set up customer search observable
    this.customerSearchObservable = this.form.controls['customer_name_search'].valueChanges
    .pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter((query: string, index: number) =>  {
        // min 2 character search length
        return query && query.length >= 2;
      }),
      switchMap(term => {
        this.loadingCustomerSearch = true;
        return this.getSearchCustomersRequest(term);
      })
    )
    .subscribe((response: any) => {
      this.loadingCustomerSearch = false;
      this.didFirstCustomerSearch = true;
      this.customers = response.data;
    });
  }

  fetchTechs() {
    let url = `tech`;

    this.api.get(url).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe(
      (response: any) => {
        if (response.status !== 'success') {
          console.log('Failed to fetch techs');
          return;
        }
        this.techniciansList = response.data;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  scheduleDateValidatorAdded: boolean = false;
  addScheduleDateValidationOnTechInput() {
    if (!this.scheduleDateValidatorAdded) {
      this.form.get('tech_id').valueChanges.subscribe(val => {
        this.form.controls['scheduled_date'].setValidators([Validators.required]);
        this.form.controls['scheduled_date'].updateValueAndValidity();
        this.scheduleDateValidatorAdded = true;
      });
    }
  }

  getSearchCustomersRequest(searchStr: string): any {
    this.customers = [];

    let url = 'customer/search';
    let data = {
      filter: searchStr,
      page_size: 50
    };

    return this.api.post(url, data);
  }

  async searchCustomers(searchStr: string){
    const results:any = await this.getSearchCustomersRequest(searchStr).toPromise();

    this.customers = results.data

    return;
  }

  patchWorkorder(): void {
    // this.client = data.data;
    for (const key in this.workOrder) {
      if (this.form.get(key) && this.workOrder[key]) {
        const value = this.workOrder[key];

        switch (key) {
          case 'customer_id':
            this.selectedCustomerId = value;
            const customerName = this.getCustomerNameById(value);
            this.form.get(key).patchValue(customerName);
            this.form.get('customer_name_search').patchValue(customerName);
            break;
          case 'chemical_datetime':
            const date = moment(value).utc().format('YYYY-MM-DD HH:mm:ss');
            this.form.get(key).patchValue(date);
            break;
            case 'estimated_time':
              // remove seconds
              const time = value.substring(0, value.length - 3);
              this.form.get(key).patchValue(time);
              break;
          default:
            this.form.get(key).patchValue(value);
            break;
        }
      }
    }
  }

  customerClick(event: any) {
    let customerName = event.option.value;

    this.selectedCustomer = customerName;
    this.selectedCustomerId = this.getSelectedCustomerId();

    this.form.controls['customer_id'].setValue(this.selectedCustomer);
    this.getCustomerPivots(this.selectedCustomerId);
  }

  getSelectedCustomerId(){
    let cid = null;

    this.customers.forEach(element => {
      if(element.customer_name == this.selectedCustomer){
        cid = element.id;
      }
    });

    return cid;
  }

  getSelectedCustomerUserId(){
    let uid = null;

    this.customers.forEach(element => {
      if(element.customer_name == this.selectedCustomer || element.id == this.selectedCustomerId){
        uid = element.user_id;
      }
    });

    return uid;
  }

  getCustomerNameById(cid){
    let customerName = '';

    this.customers.forEach(element => {
      if(element.id == cid){
        customerName = element.customer_name;
      }
    });

    return customerName;
  }

  openAddPivotDialog() {
    let userId = this.getSelectedCustomerUserId();

    const dialogRef = this.dialog.open(CreatePivotDialogComponent, {
      width: '800px',
      data: {
        customer_id: userId
      }
    })

    dialogRef.afterClosed().subscribe(async (d:any)=> {
      // Set pivot
      if(d.pivotData) {
        await this.getCustomerPivots(d.pivotData.customer_id);
        this.form.get('pivot_id').patchValue(d.pivotData.id);
      }
    })
  }

  openSuccessSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openErrorSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      panelClass: "error-snack-bar",
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  onSave(): void {
    let url = 'workorder';
    const values = {};
    if (this.id) {
      url += `/${this.id}`;
    }
    const data = this.form.getRawValue();

    for (const key in data) {
      if (data[key]) {
        values[key] = data[key];
      }
    }

    values['customer_id'] = this.selectedCustomerId;
    if (values['chemical_datetime']){
      values['chemical_datetime'] = moment(values['chemical_datetime']).utc().format('YYYY-MM-DD HH:mm:ss');
    }
    if (values['scheduled_date']){
      values['scheduled_date'] = moment(values['scheduled_date']).format('YYYY-MM-DD');
      values['status'] = 'scheduled';
    }

    let postData: any = {...values};
    if (this.isDialog) {
      postData = {...postData, ...this.dialogData}
    }

    if (!postData.pivot_id){
      postData.pivot_id = null;
    }

    this.api.post(url, {...postData}).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        this.openSuccessSnackBar('Saved!');

        const rr = this.route.snapshot.queryParams['returnRoute'];
        if(rr) {
          return this.router.navigate([rr]);
        }

        // clear the form
        this.form.reset();

        // Remove dynamic validators
        this.form.controls['scheduled_date'].setValidators([]);
        this.scheduleDateValidatorAdded = false;

        this.loading = false;
        this.pivotLoading = false;
        if (this.isDialog) {
          this.notifier.createWorkorderDialog$.next({success: true, close: true});
        }
      } else {
        let errors = '';
        if (data && data.hasOwnProperty('error')) {
          for (const x in data.error.data) {
            errors += `${data.error.data[x]}`;
          }
          this.loading = false;
        } else {
          errors = 'An error occurred';
          this.loading = false;
        }

        const errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
        this.loading = false;
      }
    });
  }

  async getOptions() {
    const results:any = await this.api.get('workorder/field-options').toPromise();
    return this.options = results.data;
  }

  async getCustomerPivots(id) {
    this.pivotLoading = true;
    this.customerPivots = null;
    const results:any = await this.api.get(`customer/${id}/pivots`).toPromise();
    this.pivotLoading = false;
    return this.customerPivots = results.data;
  }

  async getWorkOrder(wid){
    const result: any = await this.api.get(`workorder/${wid}`).toPromise();
    this.workOrder = result.data;

    return;
  }
}
