<h1>Customer</h1>

<div class="mb-20">
    <button type="button" mat-raised-button color="defualt" (click)="goBack()">
        <mat-icon class="sidenav-icon">keyboard_arrow_left</mat-icon> back
    </button>
</div>

<mat-tab-group mat-align-tabs="start">
    <!-- customer info tab -->
    <mat-tab label="Customer Info">
        <!-- customer info form -->
        <mat-card class="mat-elevation-z8 mat-typography m-20">
            <form [formGroup]="form" (ngSubmit)="onSave()" class="form-input-padding">
                <div fxLayout="row" fxLayout.lt-md="column">
                    <div fxFlex="33" fxFlex.lt-md="100">
                        <div>
                            <mat-form-field class="w-100">
                                <input
                                    matInput
                                    type="text"
                                    formControlName="customer_name"
                                    [(ngModel)]="customer.customer_name"
                                    placeholder="Customer Name">
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field class="w-100">
                                <input
                                    matInput
                                    type="text"
                                    formControlName="email"
                                    [(ngModel)]="customer.user.email"
                                    placeholder="Email">
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field class="w-100">
                                <input
                                    matInput
                                    type="text"
                                    formControlName="username"
                                    [(ngModel)]="customer.user.username"
                                    placeholder="Username">
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field class="w-100">
                                <input
                                    matInput
                                    type="password"
                                    formControlName="password"
                                    [(ngModel)]="customer.user.password"
                                    placeholder="Password">
                            </mat-form-field>
                        </div>

                        <div>
                            <!-- submit button -->
                            <button type="submit" mat-raised-button color="primary">Save</button>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card>
    </mat-tab>

    <!-- customer pivots -->
    <mat-tab label="Pivots">
        <mat-card class="mat-elevation-z8 mat-typography m-20 overflow-x-auto">
            <!-- create button -->
            <div class="mb-10">
                <button type="submit" mat-raised-button color="primary" (click)="openCreatePivotDialog()">Add</button>
            </div>

            <!-- pivots table -->
            <table mat-table [dataSource]="pivotsDataSource" class="w-100">
                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

                <!-- Legal Column -->
                <ng-container matColumnDef="legal">
                    <th mat-header-cell *matHeaderCellDef> Legal </th>
                    <td mat-cell *matCellDef="let element"> {{ element.legal }} </td>
                </ng-container>

                <!-- Brand Column -->
                <ng-container matColumnDef="brand">
                    <th mat-header-cell *matHeaderCellDef> Brand </th>
                    <td mat-cell *matCellDef="let element"> {{ element.brand }} </td>
                </ng-container>

                <!-- Mileage Column -->
                <ng-container matColumnDef="mileage">
                    <th mat-header-cell *matHeaderCellDef> Mileage </th>
                    <td mat-cell *matCellDef="let element"> {{ element.mileage }} </td>
                </ng-container>

                <!-- Field Name Column -->
                <ng-container matColumnDef="field_name">
                    <th mat-header-cell *matHeaderCellDef> Field Name </th>
                    <td mat-cell *matCellDef="let element"> {{ element.field_name }} </td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>  </th>
                    <td mat-cell *matCellDef="let element">
                        <a [routerLink]="[editBaseUrl, element.id]">Edit</a>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="pivotColumnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: pivotColumnsToDisplay;"></tr>
            </table>
        </mat-card>
    </mat-tab>
</mat-tab-group>

