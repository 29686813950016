import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { environment } from '../../environments/environment';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CustomerInterface } from '../_interfaces/customer.interface';
import { TechInterface } from '../_interfaces/tech.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

    public baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = `${environment.apiProtocol}://${environment.apiBaseUrl}/`;
    }
    get(uri: string, data: any = {}) {
        let _params = new HttpParams();

        for (const property in data) {
            _params = _params.append(property, data[property]);
        }

        return this.http.get(`${this.baseUrl}${uri}`, { params: _params, withCredentials: true });
    }

    post(uri: string, data: any = {}) {
        return this.http.post(`${this.baseUrl}${uri}`, data, { withCredentials: true });
    }

    put(uri: string, data: any = {}) {
        return this.http.put(`${this.baseUrl}${uri}`, data);
    }

    delete(uri: string, data: any = {}) {
        return this.http.delete(`${this.baseUrl}${uri}`, data);
    }

    searchCustomer(uri: string, data: any = {}) : Observable<CustomerInterface[]> {
        return this.http.post(`${this.baseUrl}${uri}`, data, { withCredentials: true }).pipe(
            map(res =>  res['data'])
        );
    }

    searchTech(uri: string, data: any = {}) : Observable<TechInterface[]> {
        return this.http.post(`${this.baseUrl}${uri}`, data, { withCredentials: true }).pipe(
            map(res =>  res['data'])
        );
    }

    searchStaff(uri: string, data: any = {}) : Observable<TechInterface[]> {
        return this.http.post(`${this.baseUrl}${uri}`, data, { withCredentials: true }).pipe(
            map(res =>  res['data'])
        );
    }
}

