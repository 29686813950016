import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "./shared.module";
import { Routes, RouterModule } from '@angular/router';

import { TechIndexComponent } from '../pages/shared/tech/tech-index/tech-index.component';
import { CustomerIndexComponent } from '../pages/shared/customer/customer-index/customer-index.component';
import { CustomerShowComponent } from '../pages/shared/customer/customer-show/customer-show.component';

import { CreateCustomerDialogComponent } from '../_dialogs/shared/create-customer-dialog/create-customer-dialog.component';
import { PivotShowComponent } from '../pages/shared/pivot/pivot-show/pivot-show.component';
import { TechShowComponent } from '../pages/shared/tech/tech-show/tech-show.component';

import { WorkorderIndexComponent } from '../pages/shared/workorder/workorder-index/workorder-index.component';

import { ScheduleIndexComponent } from '../pages/shared/schedule/schedule-index/schedule-index.component';

import { ConfirmDialogComponent } from '../_dialogs/shared/confirm-dialog/confirm-dialog.component';
import { CreatePivotDialogComponent } from '../_dialogs/shared/create-pivot-dialog/create-pivot-dialog.component';
import { CreateTechDialogComponent } from '../_dialogs/shared/create-tech-dialog/create-tech-dialog.component';
import { CreateWorkorderComponent } from "../pages/shared/workorder/create-workorder/create-workorder.component";
import { InvoicedWorkordersIndexComponent } from '../pages/shared/workorder/invoiced-workorders-index/invoiced-workorders-index.component';
import { AddWorkOrderDialogComponent } from "../_dialogs/shared/add-work-order-dialog/add-work-order-dialog.component";
import { WorkorderCustomerNameSearchPipe } from "../_pipes/workorder-customer-name-search.pipe";
import { ViewWorkorderDialogComponent } from "../_dialogs/shared/view-workorder-dialog/view-workorder-dialog.component";
import { EditCustomerDialogComponent } from "../_dialogs/shared/edit-customer-dialog/edit-customer-dialog.component";
import { CreateWorkorderDialogComponent } from "../_dialogs/shared/create-workorder-dialog/create-workorder-dialog.component";

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

@NgModule({
  declarations: [
    TechIndexComponent,
    CustomerIndexComponent,
    CustomerShowComponent,
    PivotShowComponent,
    TechShowComponent,
    ConfirmDialogComponent,
    CreateCustomerDialogComponent,
    CreatePivotDialogComponent,
    CreateTechDialogComponent,
    WorkorderIndexComponent,
    ScheduleIndexComponent,
    CreateWorkorderComponent,
    InvoicedWorkordersIndexComponent,
    AddWorkOrderDialogComponent,
    WorkorderCustomerNameSearchPipe,
    ViewWorkorderDialogComponent,
    EditCustomerDialogComponent,
    CreateWorkorderDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
    exports: [
        CreateWorkorderComponent
    ]
})
export class CrudSharedModule {
}
