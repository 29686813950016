<div fxLayout="row" fxLayoutAlign="start center" >
    <h1 class="mr-4">Techs</h1>
    <!-- loading spinner -->
    <div *ngIf="dataSource.loading$ | async">
        <mat-spinner [diameter]="40"></mat-spinner>
    </div>
</div>

<!-- create button -->
<div class="mb-20">
    <button type="submit" mat-raised-button color="primary" (click)="openCreateModal()">Add</button>
</div>

<!-- search field -->
<mat-form-field class="w-100">
    <mat-label>Search</mat-label>
    <input matInput placeholder="Name..." #input>
</mat-form-field>

<div class="mat-elevation-z8 mb-4 overflow-x-auto">
    <table mat-table [dataSource]="dataSource" matSort class="w-100">
        <!-- First Name Column -->
        <ng-container matColumnDef="first_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
            <td mat-cell *matCellDef="let row"> {{row.user.first_name}} </td>
        </ng-container>

        <!-- Last Name Column -->
        <ng-container matColumnDef="last_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
            <td mat-cell *matCellDef="let row"> {{row.user.last_name}} </td>
        </ng-container>

        <!-- Username Column -->
        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
            <td mat-cell *matCellDef="let row"> {{row.user.username}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let row"> {{row.user.email}} </td>
        </ng-container>

        <!-- Active -->
        <ng-container matColumnDef="set_inactive_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
            <td mat-cell *matCellDef="let row"> {{ row.set_inactive_at ? 'No' : 'Yes' }} </td>
        </ng-container>

        <!-- actions column-->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <a [routerLink]="[editBaseUrl, row.id]">Edit</a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data -->
        <tr class="mat-row" *matNoDataRow>
           <td class="mat-cell" colspan="4">No data matching the filter</td>
        </tr>
    </table>

    <mat-paginator [length]="techInfo?.num_techs" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
