import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workorderCustomerNameSearch'
})
export class WorkorderCustomerNameSearchPipe implements PipeTransform {

  transform(values: any[], search:any): any {
    if(!search) return values;
  }

}
