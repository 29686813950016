import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Router } from '@angular/router';

import { UserService } from '../_services/user.service';

@Injectable()
export class StaffAuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private userService: UserService
    ){}

    canActivate() {
        if (this.userService.userIsLoggedIn() && this.userService.userIsStaff()) {
            return true;
        }

        this.router.navigate(['/guest/login']);

        return false;
    }

    canActivateChild() {
        if (this.userService.userIsLoggedIn()) {
            return true;
        }

        this.router.navigate(['/guest/login']);

        return false;
    }
}
