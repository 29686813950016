import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from "@angular/material/dialog";

import { catchError } from "rxjs/operators";
import { of } from "rxjs";

import { ViewWorkorderDialogComponent } from "../../../../_dialogs/shared/view-workorder-dialog/view-workorder-dialog.component";
import { ApiService } from "../../../../_services/api.service";

import * as moment from "moment";

@Component({
  selector: 'app-invoiced-workorders-index',
  templateUrl: './invoiced-workorders-index.component.html',
  styleUrls: ['./invoiced-workorders-index.component.scss']
})
export class InvoicedWorkordersIndexComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['customer_name', 'schedule_date', 'pivot', 'problem_type', 'quickbook_status'];
  dataSource: any;
  loading = true;

  invoiced: any = [];

  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }

  constructor(
    private api: ApiService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.invoiced = [];
    this.init();
  }

  async init(){
    await this.getInvoiced();

    this.dataSource = new MatTableDataSource<any>(this.invoiced);

    this.dataSource.paginator = this.paginator;
  }

  formatDateString(string){
    return moment(string).format('MM/DD/YYYY');
  }

  async getInvoiced(data = null): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const url = 'workorder/invoiced';
      const translateQBStatus = (status: string): string => {
        switch (status) {
          case 'e':
            return 'Error';
          case 'h':
            return 'Error';
          case 'i':
            return 'Error';
          case 'q':
            return 'Queued';
          case 's':
            return 'Success';
        }
      };

      this.api.get(url).pipe(catchError((err: any) => {
        return of(err);
      })).subscribe((response: any) => {
        if (response.status === 'fail') {
          console.log('Get invoiced failed');
          reject(false);
        } else {
          this.invoiced = response.data;
          this.invoiced.forEach((row: any) => {
            row.quickbook_status.qb_status = translateQBStatus(row.quickbook_status.qb_status);
          });
          resolve(true);
          this.loading = false;
        }
      });
    });
  }

  openViewWorkorderDetails(item) {
    const dialogRef = this.dialog.open(ViewWorkorderDialogComponent, {
      width: '800px',
      data: {
        schedule: false,
        invoiced: true,
        ...item
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.success){
        this.init();
      }
    });
  }
}

