import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';

import {ApiService} from '../../../_services/api.service';

@Component({
  selector: 'app-edit-customer-dialog',
  templateUrl: './edit-customer-dialog.component.html',
  styleUrls: ['./edit-customer-dialog.component.scss']
})
export class EditCustomerDialogComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  customerId: any;
  customer: any = {};

  form: FormGroup;
  submitted: Boolean = false;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      customer_name: ['', [Validators.required]],
      /*username: ['', [Validators.required]],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],*/
    });
  }

  ngOnInit(): void {
    for (const key in this.data) {
      if (this.form.get(key)) {
        this.form.get(key).patchValue(this.data[key]);
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let url = `customer/${this.data.id}`;
    let data = this.form.getRawValue();

    this.api.post(url, data).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        // this.client = data.data;
        this.openSuccessSnackBar('Saved!');
        this.onClose(data.data.id);
      } else {
        let errors = '';
        if (data && data.hasOwnProperty('error')) {
          for (const x in data.error.data) {
            errors += `${data.error.data[x]}`;
          }
        } else {
          errors = 'An error occurred';
        }

        let errorMsg = 'Error: ' + errors;
        this.openErrorSnackBar(errorMsg);
      }
    });
  }

  openErrorSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      panelClass: "error-snack-bar",
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openSuccessSnackBar(message) {
    this._snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  onClose(id = null) {
    let data = {
      customerId: id
    };
    this.dialogRef.close(data);
  }
}
