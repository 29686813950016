import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    token: string;

    constructor(
        private api: ApiService,
        private router: Router,
    ) {
    }

    get status() {
        return !!localStorage.getItem('currentUser');
    }

    get user() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    setUser(user) {
        localStorage.setItem('currentUser', JSON.stringify(user));
    }

    deleteUser(){
        delete localStorage.currentUser;
    }

    register(registerData: any) {
        return new Promise((resolve, reject) => {
            this.api.post(`register`, registerData)
            .subscribe((res: any) => {
                let user = res.data;
                if (user) {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }
                resolve(res);
            }, (err: any) => {
                resolve(err);
            });
        });
    }

    login(loginData: any) {
        return new Promise((resolve, reject) => {
            this.api.post(`login`, loginData)
            .subscribe((res: any) => {
                let user = res.data;
                if (user) {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }
                resolve(res);
            }, (err: any) => {
                resolve(err);
            });
        });
    }

    // navigates to sign in page on success
    logout() {
        return new Promise((resolve, reject) => {
            this.api.post(`logout`)
            .subscribe((res: any) => {
                this.deleteUser();
                this.router.navigate(['/guest/login']);
                resolve(res);
            }, (err: any) => {
                resolve(err);
            });
        });

        location.reload(true);
    }

    userIsLoggedIn(){
        return !!localStorage.getItem('currentUser')
    }

    userIsAdmin(){
        this.user;

        if(!this.user){
            return false;
        }

        return this.user.role == environment.roleAdmin;
    }

    userIsStaff(){
        if(!this.user){
            return false;
        }

        return this.user.role == environment.roleStaff;
    }
}
