import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { catchError } from "rxjs/operators";
import { of } from "rxjs";

import { ViewWorkorderDialogComponent } from "../../../../_dialogs/shared/view-workorder-dialog/view-workorder-dialog.component";
import { ApiService } from "../../../../_services/api.service";

import * as moment from "moment";

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-workorder-index',
  templateUrl: './workorder-index.component.html',
  styleUrls: ['./workorder-index.component.scss']
})
export class WorkorderIndexComponent implements OnInit {
  objectKeys = Object.keys;

  priorityOptions: any = [];
  form: FormGroup;
  unscheduled: any = [];
  scheduled: any = [];
  readyToBill: any = [];
  loading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.form = this.fb.group({
      selectedDate: [moment().format('YYYY-MM-DD'), [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.initData();
  }

  async initData(){
    this.loading = true;

    await this.getUnscheduled();
    await this.getSchedule();
    await this.getReadyToBill();

    this.loading = false;
  }

  async getSchedule(){
    return new Promise((resolve, reject) => {
      const selectedDate = moment(this.form.get('selectedDate').value).format('YYYY-MM-DD');
      let data = {
        date: selectedDate
      };
      let url = `workorder/schedule`;

      this.api.get(url, data).pipe(catchError((err: any) => {
        return of(err);
      })).subscribe((data: any) => {
        if (data.status === 'fail'){
          console.log('Get schedule failed');
          reject(false);
        }else{
          // this.scheduled = data.data;
          let destructured = [];
          for (let entry of data.data) {
            for (let wo of entry.work_orders) {
              wo = {...wo, tech: entry.user};
              destructured.push(wo);
            }
          }
          this.scheduled = destructured;
          resolve(true);
        }
      });
    });
  }

  editWorkOrder(id) {
    // https://localhost:4200/staff/workorder/edit/36?returnRoute=staff%2Fschedule%2Findex
    // Get the route to pass

    const index:number = this.router.url.indexOf('admin');
    let url = `/${index !== -1 ? 'admin' : 'staff'}/workorder/edit/${id}?returnRoute=${this.router.url}`
    this.router.navigateByUrl(url);
    // this.onClose();
  }

  getWoPriorityColor(wo){
    if(wo.priority_info){
      return wo.priority_info.color;
    }else{
      return '#ffffff';
    }
  }

  openViewWorkorderDetails(item) {
    const dialogRef = this.dialog.open(ViewWorkorderDialogComponent, {
      width: '800px',
      data: {
        schedule: false,
        ...item
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.success){
        this.getSchedule();
        this.getUnscheduled();
      }
    });
  }

  openViewWorkorder(item) {
    const dialogRef = this.dialog.open(ViewWorkorderDialogComponent, {
      width: '800px',
      data: {
        schedule: true,
        ...item
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.success){
        this.initData();
      }
    });
  }

  async getReadyToBill() {
    return new Promise((resolve, reject) => {
      this.api.get('workorder/quickbooks-ready').subscribe((data:any)=> {
        this.readyToBill = Object.keys(data.data).reduce((a, c) => (a[c] = data.data[c], a), {});
        resolve(true);
      });
    });
  }

  async getUnscheduled(data = null) {
    return new Promise((resolve, reject) => {
      let url = 'workorder/unscheduled';
      if (data) url = `${url}?customer_name=${data}`;
      this.api.get(url).pipe(catchError((err: any) => {
        return of(err);
      })).subscribe((data: any) => {
        if (data.status === 'fail') {
          console.log('Get unscheduled failed');
          reject(false);
        } else {
          this.unscheduled = data.data;
          resolve(true);
        }
      });
    });
  }

  downloadDaysWorkOrders() {
    let url = `${environment.apiProtocol}://${environment.apiBaseUrl}/workorders/daily`
    url = url + '/' + moment().format('YYYY-MM-DD')

    window.open(url, '_blank').focus();
  }

  formatDateString(string){
    return moment(string).format('MM/DD/YYYY')
  }
}
