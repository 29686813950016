<h1>Pivot</h1>

<div class="mb-20">
  <button type="button" mat-raised-button color="defualt" (click)="goBack()">
    <mat-icon class="sidenav-icon">keyboard_arrow_left</mat-icon>
    back
  </button>
</div>

<!-- form -->
<mat-card class="mat-typography">
  <form [formGroup]="form" (ngSubmit)="onSave()" class="form-input-padding">
    <div fxLayout="row" fxLayout.lt-md="column">
      <div fxFlex="33" fxFlex.lt-md="100">
        <div>
          <mat-form-field class="w-100">
            <input
              matInput
              type="text"
              formControlName="legal"
              [(ngModel)]="pivot.legal"
              placeholder="Legal">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="w-100">
            <input
              matInput
              type="text"
              formControlName="brand"
              [(ngModel)]="pivot.brand"
              placeholder="Brand">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="w-100">
            <input
              matInput
              type="text"
              formControlName="mileage"
              [(ngModel)]="pivot.mileage"
              placeholder="Mileage">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="w-100">
            <input
              matInput
              type="text"
              formControlName="field_name"
              [(ngModel)]="pivot.field_name"
              placeholder="Field Name">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="w-100">
            <input
              matInput
              type="text"
              formControlName="latitude"
              [(ngModel)]="pivot.latitude"
              placeholder="Latitude">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="w-100">
            <input
              matInput
              type="text"
              formControlName="longitude"
              [(ngModel)]="pivot.longitude"
              placeholder="Longitude">
          </mat-form-field>
        </div>

        <div>
          <!-- submit button -->
          <button type="submit" mat-raised-button color="primary">Save</button>
          <!-- delete button -->
          <button class="float-right" type="button" mat-raised-button color="warn" (click)="openConfirmDelete()">
            Delete
          </button>
        </div>
      </div>
    </div>
  </form>
</mat-card>
