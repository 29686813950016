import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { MatDialog } from '@angular/material/dialog';

import { Observable, BehaviorSubject, of, merge, fromEvent, } from 'rxjs';
import { catchError, finalize, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { CreateTechDialogComponent } from "../../../../_dialogs/shared/create-tech-dialog/create-tech-dialog.component";
import { TechInterface } from '../../../../_interfaces/tech.interface';
import { ApiService } from '../../../../_services/api.service';
import { UserService } from '../../../../_services/user.service';

@Component({
  selector: 'app-tech-index',
  templateUrl: './tech-index.component.html',
  styleUrls: ['./tech-index.component.scss']
})
export class TechIndexComponent implements OnInit {
  techInfo: any;
  displayedColumns: string[] = ['first_name', 'last_name', 'email', 'username', 'set_inactive_at', 'actions'];
  dataSource: TechDataSource;

  editBaseUrl: string = '';

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit() {
    this.techInfo = this.route.snapshot.data["techInfo"];
    this.dataSource = new TechDataSource(this.apiService);
    this.dataSource.loadTech('', '', '', 0, 10);

    this.editBaseUrl = (this.userService.userIsAdmin()) ? '/admin/tech/show' : '/staff/tech/show'
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent(this.input.nativeElement, 'keyup')
    .pipe(
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.paginator.pageIndex = 0;
        this.loadTechPage();
      })
    )
    .subscribe();

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
      tap(() => this.loadTechPage())
    )
    .subscribe();
  }

  loadTechPage() {
    this.dataSource.loadTech(
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
  }

  refreshTech(){
    let url = 'vendor/tech/total';
    this.apiService.get(url).pipe(catchError((err: any) => {
      return of(err);
    })).subscribe((data: any) => {
      if (data.status === 'success') {
        this.techInfo = data.data;
        this.loadTechPage();
      }else{
        console.log('An error occurred retriving tech info data');
      }
    });
  }

  openCreateModal(){
    const dialogRef = this.dialog.open(CreateTechDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.techId){
        // switch to edit page

        if(this.userService.userIsAdmin()){
          this.router.navigate(['/admin/tech/show', result.techId]);
        }else{
          this.router.navigate(['/staff/tech/show', result.techId]);
        }
      }
    });
  }
}

class TechDataSource implements DataSource<TechInterface> {
  private techSubject = new BehaviorSubject<TechInterface[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<TechInterface[]> {
      return this.techSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
      this.techSubject.complete();
      this.techSubject.complete();
  }

  loadTech(filter = '', sortDirection = 'asc', sortActive='', pageIndex = 0, pageSize = 5) {
    this.loadingSubject.next(true);

    let url = 'tech/search';
    
    let data = {
      filter: filter,
      sort_direction: sortDirection,
      sort_active: sortActive,
      page_index: pageIndex,
      page_size: pageSize,
    };

    this.apiService.searchTech(url, data).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
    .subscribe(tech => {
      this.techSubject.next(tech)
    });
  }   
}
